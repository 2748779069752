import { LitElement, html, css, nothing } from 'lit';
import '@material/mwc-icon';
import '@material/mwc-button';
import '@material/mwc-icon-button';
import '@material/mwc-dialog';
import '@material/mwc-menu';
import '@material/mwc-drawer';
import '@material/mwc-textfield';
import '@material/mwc-top-app-bar-fixed';
import '../shared-components/form.js';

const page_styles = css`
        :host {
          font-family: 'Roboto', 'Noto', sans-serif;
          width: 100%;
          height: 100%;
          overflow: hidden;

          --top-bar-color: var(--paper-green-700);
          --top-bar-text: white;
          --default-theme-primary: #6200ee;
          --default-theme-on-primary: #ffffff;
        }

        .default-padding {
            box-sizing: border-box;
            padding: 24px;
        }

        .flex-vertical {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: center;
          align-items: flex-start;
        }
        .flex-horizontal {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          align-items: flex-start;
        }

        #content {
          width: 100%;
          height: calc(100vh - 64px);
          overflow: overlay;
        }
        .menu_container {
            position: relative;
            width: 100%;
            text-align: right;

            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-end;
            align-items: center;

            --mdc-theme-primary: var(--top-bar-text, white);
        }
        [slot=actionItems] {
            --mdc-theme-primary: var(--top-bar-text, white);
        }

        #kebab_menu {
            position: relative;
            top: -18px;
        }
    
        #title {
            position: relative;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-end;
            align-items: center;
            overflow: visible;
        }
        #title > * {
            margin-right: 18px;
        }
        #main_title {

        }
        #subtitle {
        }
`;

class KalePage extends LitElement {
    static styles = page_styles
    static default_title = "Template"
    static icon = "title"
    actions = [
        {
            name: 'Preferences',
            icon: 'account_circle',
            //link: '/prefs'
            action: () => window.location.href = '/prefs',
            kebab_only: true
        },
        {
            name: 'Sign out',
            icon: 'exit_to_app',
            action: () => this.dispatchEvent(new CustomEvent('signout', { bubbles: true, composed: true, detail: null })),
            kebab_only: true
        },
    ]
    constructor() {
        super();
        this.title = this.constructor.default_title;
    }
    /*

            <div class="column">
              <div class="card">
              prefs
              </div>
            </div>

    */
    static constructAddress(location, args) {
        return location.href;
    }
   

    renderMenu() {

    }

    renderPage() {
        return html``
    }
    renderTitle() {
        return html`<span id="main_title">${this.title}</span>`
    }

    showKebabMenu() {
        this.menu_open = true;
        
        this.updateComplete.then(() => {
        const button = this.renderRoot.getElementById('kebab_button');
        const menu = this.renderRoot.getElementById('kebab_menu');
        menu.anchor = button;
        menu.show();
      });
    }
    //<a href="/" slot="navigationIcon" style="color: white"><mwc-icon-button title="Home" icon="arrow_back" ></mwc-icon-button></a>

    openNav() {
        this.dispatchEvent(new CustomEvent('nav-drawer', { bubbles: true, composed: true, detail: null }))

    } 
    evalItem(item) {
        const { link, action, name, icon, full, kebab_only } = item;
        return {
            action,
            link: typeof(link) === 'function' ? link() : link,
            name: typeof(name) === 'function' ? name() : name,
            icon: typeof(icon) === 'function' ? icon() : icon,
            full: typeof(full) === 'function' ? full() : full,
            kebab_only: typeof(kebab_only) === 'function' ? kebab_only() : kebab_only,
        }
    }
    renderActionItem(item) {
        const { link, action, name, icon, full, kebab_only } = item;
        if (kebab_only) return '';
        if (action) {
            return full ? html`<mwc-button slot="actionItems" title=${name} icon=${icon} @click=${action}>${name}</mwc-button>` : html`<mwc-icon-button slot="actionItems" title=${name} icon=${icon} @click=${action}></mwc-icon-button>`;
        }

    }
    renderMenuItem(item) {
        const { link, action, name, icon } = item;
        if (action) {
            return html`<mwc-list-item @request-selected=${action} graphic="icon">${icon ? html`<mwc-icon slot="graphic">${icon}</mwc-icon>` : ''}${name}</mwc-list-item>`
        }
    }
    renderExtraItems(){ 
        return '';
    }
    renderActionItems(actions){
        actions = actions.map(k => this.evalItem(k));
        const top_bar_actions = actions.filter(k => !k.kebab_only);
        const kebab_actions = actions.filter(k => k.kebab_only);
        return html`
                        ${top_bar_actions.map(k => this.renderActionItem(k))}
                        <mwc-icon-button slot="actionItems" title="More" id="kebab_button" icon="more_vert" @click=${this.showKebabMenu}>
                          ${this.menu_open ? html`<mwc-menu absolute corner="BOTTOM_START" id="kebab_menu">${kebab_actions.map(k => this.renderMenuItem(k))}</mwc-menu>` : ''}
                        </mwc-icon-button>
                        `;
    }

    render() {
        const { icon } = this.constructor;
        const { title, subtitle, actions } = this;
        return html`
     
                <mwc-top-app-bar-fixed id="top_bar" style="--mdc-theme-primary: var(--top-bar-color); --mdc-theme-on-primary: var(--top-bar-text); position: relative;" >
                    <mwc-icon-button slot="navigationIcon" title="Navigation" id="nav_drawer_button" icon="menu" @click=${this.openNav}></mwc-icon-button>
                    <div slot="title" id="title">
                        ${this?.renderPreTitle?.() || ''}
                        ${icon && false ? html`<mwc-icon >${icon}</mwc-icon>` : ''}${this.renderTitle()}
                    </div>
                    
                    ${this.renderExtraItems()}
                    ${this.renderActionItems(actions)}
                    <div id="content" style="--mdc-theme-primary: var(--default-theme-primary); --mdc-theme-on-primary: var(--default-theme-on-primary);">
                        ${this.renderPage()}
                    </div>
                </mwc-top-app-bar-fixed>


        `;
        /*<div style="position: relative; top: -4px">
                    <div class="menu_container" slot="actionItems" >
                    </div>

style="font-size: 150%; position: relative; top: 8px; margin-right: 12px;"
        */
    }

    set scroller(target) {
        const bar = this.renderRoot.getElementById('top_bar');
        //this.scroller = this.renderRoot.getElementById('content');
        bar.scrollTarget = target;
    }
    firstUpdated() {
        this.scroller = this.renderRoot.getElementById('content');
        this.addEventListener('set-scroller', e => this.scroller = e.detail);
    }


    activateRoute() {
    }

    static get properties() {
        return {
            search_results: { type: Array },
            title: {type: String },
            subtitle: {type: String },
            menu_open: { type: Boolean }
        };
    }
}

window.customElements.define('kale-page', KalePage);
export { KalePage }

