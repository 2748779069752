import { LitElement, html, css } from 'lit';
import '@material/mwc-icon';
import '@material/mwc-button';
//import 'lit/directives/class-map.js';

import { KaleForm, KaleTextField, KaleDate, KaleToggle, KaleEnum } from '../shared-components/form.js';
import { shadows } from '../shared-components/styles.js';

const card_style = css`
        :host {
          box-sizing: border-box;
          font-family: 'Roboto', 'Noto', sans-serif;
         --shadow-transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1), filter 0.28s cubic-bezier(0.4, 0, 0.2, 1);
         --bg-transition: background-color 0.28s cubic-bezier(0.4, 0, 0.2, 1);
         --border-transition: border 0.28s cubic-bezier(0.4, 0, 0.2, 1);
         ${shadows}
        }
        ::slotted(*) {
        box-sizing: border-box;
        margin: 0;
        padding: 20px 20px 00px 20px;
        width: 100%;
        }

        ::slotted(:first-child) {
          border-top-left-radius: var(--card-radius, 8px);
          border-top-right-radius: var(--card-radius, 8px);
        }
        ::slotted(:last-child) {
          padding: 20px 20px 20px 20px;
          border-bottom-left-radius: var(--card-radius, 8px);
          border-bottom-right-radius: var(--card-radius, 8px);
        }
        ::slotted([slot="full-bleed"]) {
        padding: 00px !important;
        }       
        .card {
          width: inherit;
          overflow: visible;
          box-sizing: border-box;
          background-color: var(--card-bg, white);
          border: 1px solid var(--card-border, var(--paper-grey-400));
          padding: inherit;
          /*padding-bottom: 16px;*/
          transition: var(--shadow-transition_-_transition), var(--bg-transition), var(--border-transition);
          cursor: pointer;
          border-radius: var(--card-radius, 8px);
          overflow: hidden;
        }

      .column {
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: stretch;
        flex-direction: column; 

        padding: 0;
        height: 100%;
      }
      .column > * {
        box-sizing: border-box;
        margin: 0;
        padding: 20px;
        width: 100%;
      }
        .card:hover {
          /*box-shadow: var(--shadow-elevation-8dp_-_box-shadow); */
          box-shadow: var(--shadow-elevation-8dp_-_box-shadow);

        }
`

class KaleCard extends LitElement {
  static styles = card_style
  static form_name = "Card"
  constructor() {
    super();
  }
  render() {
    return html`
        <div class="card column">
          <slot name="full-bleed"></slot>
          <slot></slot>
        </div>

      `;
    /*<div>${this.valid ? "valid" : "invalid"}</div>
            <ul>
            ${[...this.invalid_map.entries()].map(([name, e]) => {
             console.log('entry:', e);
            return html`
              <li>${e.field ? 'field' : 'form'}: ${e.name ? e.name : e.field} : ${e.dirty ? 'dirty' : 'clean'} : ${e.valid ? 'valid' : 'invalid'} </li>`
            })}
            </ul>


                  headerlabel="New Person"
                  acceptlabel="Create"
                  declinelabel="Cancel"
                  @accept=${e => {this.add_person_error=null; this.save();}}}
                  @cancel=${e => {this.add_person = false; this.setToDefault(); this.add_person_error=null;}}
    */
  }

  static get properties() {
    return {
      ...(super.properties)
    };
  }
}

window.customElements.define('kale-card', KaleCard);
export { KaleCard }
