import { css } from 'lit';
// FIXME: need to make sure paper colors are imported+defined?

const timeline_colors = css`
    --deceased-person-color: var(--paper-grey-900);
    --working-retiree-person-color: rgb(154, 167, 53);
    --employee-person-color: var(--paper-green-700);
    --ineligible-person-color: var(--paper-grey-700);
    --ex-employee-person-color: var(--paper-blue-700);
    --error-person-color: var(--paper-red-700);
    --retiree-person-color: var(--paper-yellow-700);
    --disability-person-color: var(--paper-yellow-700);

    --deceased-person-alt-color: var(--paper-grey-900);
    --working-retiree-person-alt-color: rgb(154, 167, 53);
    --employee-person-alt-color: var(--paper-green-800);
    --ineligible-person-alt-color: var(--paper-grey-700);
    --ex-employee-person-alt-color: var(--paper-blue-700);
    --error-person-alt-color: var(--paper-red-700);
    --retiree-person-alt-color: var(--paper-yellow-900);
    --disability-person-alt-color: var(--paper-yellow-900);

    --participation-color: var(--paper-orange-600);
    --projection-color: var(--paper-light-green-600);
    --timeline-color: var(--paper-purple-600);
    --timeline-color-break: var(--paper-purple-200);
    --suspension-color: var(--paper-cyan-600);
    --employment-color: var(--paper-green-600);
    --ineligible-color: var(--paper-grey-600);
    --participation-events-color: var(--paper-green-600);
    --employment-color-break: var(--paper-green-200);
    --benefit-color: var(--paper-yellow-700);
    --benefit-marker-color: var(--paper-yellow-900);
    --eligibility-color: var(--paper-yellow-900);
    --beneficiary-color: var(--paper-purple-600);
    --benefactor-color: var(--paper-pink-600);
    --contribution-color: var(--paper-teal-600);
    --payment-color: var(--paper-light-green-800);
    --deceased-color: var(--paper-grey-800);
    --adjustment-color: var(--paper-indigo-600);
    --auto-adjustment-color: var(--paper-indigo-500);
    --withdrawal-color: var(--paper-light-green-700);
    --projection-color: var(--paper-light-green-600);
    --election-color: var(--paper-orange-600);
    --error-color: var(--paper-red-700);
    --migration-color: var(--paper-pink-800);
    --alert-color: var(--paper-pink-500);
    /*--alert-color: var(--paper-deep-orange-a400);*/
    --events-color: var(--paper-grey-700);
    --reset-color: var(--paper-grey-900);
    `;

const timeline_classes = css`
  .ineligible { --timeline-primary-color: var(--ineligible-color); --timeline-text-color: white;}
  .employment { --timeline-primary-color: var(--employment-color); --timeline-text-color: white;}
  .suspension { --timeline-primary-color: var(--suspension-color); --timeline-text-color: white;}
  .benefit, .retirement { --timeline-primary-color: var(--benefit-color); --timeline-text-color: var(--paper-grey-800)}
  .automatic_adjustment { --timeline-primary-color: var(--adjustment-color); --timeline-text-color: white;}
  .manual_adjustment { --timeline-primary-color: var(--adjustment-color); --timeline-text-color: white;}
  .part_time_hours { --timeline-primary-color: var(--employment-color); --timeline-text-color: white;}
  .service_reset { --timeline-primary-color: var(--reset-color); --timeline-text-color: white;}
  .contribution { --timeline-primary-color: var(--contribution-color); --timeline-text-color: white;}
  .participation { --timeline-primary-color: var(--participation-events-color); --timeline-text-color: white;}
  .payment { --timeline-primary-color: var(--payment-color); --timeline-text-color: white;}
  .beneficiary { --timeline-primary-color: var(--beneficiary-color); --timeline-text-color: white;}
  .benefactor { --timeline-primary-color: var(--benefactor-color); --timeline-text-color: white;}
  .withdrawal { --timeline-primary-color: var(--withdrawal-color); --timeline-text-color: white;}
  .opt_out { --timeline-primary-color: var(--election-color); --timeline-text-color: white;}
  .opt_in { --timeline-primary-color: var(--election-color); --timeline-text-color: white;}
  .deficient_contributions { --timeline-primary-color: var(--error-color); --timeline-text-color: white;}
  .error { --timeline-primary-color: var(--error-color); --timeline-text-color: white;}
  .migration { --timeline-primary-color: var(--migration-color); --timeline-text-color: white;}
  .alert { --timeline-primary-color: var(--alert-color); --timeline-text-color: white;}
  .part_time_hours { --timeline-primary-color: var(--employment-color); --timeline-text-color: white;}
  .participation_begin { --timeline-primary-color: var(--participation-events-color); --timeline-text-color: white;}
  .contribution_begin { --timeline-primary-color: var(--participation-events-color); --timeline-text-color: white;}
  .vesting { --timeline-primary-color: var(--participation-events-color); --timeline-text-color: white;}
  .benefit_eligibility { --timeline-primary-color: var(--eligibility-color); --timeline-text-color: white;}
  .retirement_eligibility { --timeline-primary-color: var(--eligibility-color); --timeline-text-color: white;}
  .present { --timeline-primary-color: var(--events-color); --timeline-text-color: white;}
  .deceased { --timeline-primary-color: var(--deceased-color); --timeline-text-color: white;}
`;


const transitions = css`
    --shadow-elevated: var(--shadow-elevation-8dp_-_box-shadow);
    --shadow-transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1), filter 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    --stroke-width-transition: stroke-width 0.28s cubic-bezier(0.4, 0, 0.2, 1), filter 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    --font-transition: font-size 0.28s cubic-bezier(0.4, 0, 0.2, 1), font-weight 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    --transform-transition: transform 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    --opacity-transition: opacity 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    --bg-transition: background-color 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    --border-transition: border-color 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    `;



export { timeline_colors, timeline_classes, transitions };
