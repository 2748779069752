export const wait = ms => new Promise((r, j) => setTimeout(r, ms))

export async function trueAfter(ms) {
  await wait(ms);
  return true;
}

export const transitionToPromise = (el, property, value, timeout = 500) =>
  Promise.race([
    wait(timeout),
    new Promise(resolve => {
      el.style[property] = value;
      const transitionEnded = e => {
        if (e.propertyName !== property) return;
        el.removeEventListener('transitionend', transitionEnded);
        resolve();
      }
      el.addEventListener('transitionend', transitionEnded);
    })]);

export const doTransition = (el, property, value, timeout = 500) =>
  Promise.race([
    wait(timeout),
    new Promise(resolve => {
      el.style[property] = value;
      const transitionEnded = e => {
        if (e.propertyName !== property) return;
        el.removeEventListener('transitionend', transitionEnded);
        resolve();
      }
      el.addEventListener('transitionend', transitionEnded);
    })]);

export const transitionEnd = (el, timeout = 500) =>
  Promise.race([
    wait(timeout),
    new Promise(resolve => {
      const transitionEnded = e => {
        el.removeEventListener('transitionend', transitionEnded);
        resolve();
      }
      el.addEventListener('transitionend', transitionEnded);
    })]);


export const animationFrame = () =>
  new Promise(resolve => {
    window.requestAnimationFrame(resolve);
  });

export const animationEnd = (el) =>
  new Promise(resolve => {
    const animEnded = e => {
      el.removeEventListener('animationend', animEnded);
      resolve();
    }
    el.addEventListener('animationend', animEnded);
  });



/*
let material_decel = [0, 0, .2, 1];
let material_std = [.4, 0, .2, 1];
let material_accel = [.4, 0, 1, 1];
let material_sharp = [.4, 0, .6, 1];


let smoothstart = t => t * t;
let smoothstop = t => (1 - t) * (1 - t);
let quickstart = t => Math.sqrt(1 - t);
let quickstop = t => 1 - Math.sqrt(t);
let mix = (a, b, weightb, t) => a + weightb * (b - a);
*/