import gql from 'graphql-tag';
import { StaticQuery, SaveableQuery } from './client.js';


export const refetch_searches = ["target_search", "agreement_search", "unit_increase_search"];

export const period_stats_fields = gql`
  fragment PeriodStats on md_vm_fast_period_stats {
    year
    total_complete
    total_average_increase
    total_stat
    total_members
    total_members_not_in_negotiation
  } 
  `;
  //query locals_search($include_deprecated: Boolean=false, $limit: Int=20, $tscursor: String, $period: Int, $filter: String, $state: String, $council: Int, $local: Int, $hasmaster: Boolean, $order: [md_local_order_by!]){
  // export const recompute_period = gql`mutation recompute($y: Int!)  {
  //   md_fn_recompute(args: {period_year: $y}) { 
  //        ...PeriodStats
  //     } }
  //     ${period_stats_fields}
  //   `;

  export const recompute_period = gql`
mutation recompute($year:Int!) {
  md_fn_recompute_period(args: {period_year: $year}) {
          ...PeriodStats
  }
}
       ${period_stats_fields}
`;
  // ${period_stats_fields}
export const import_file_fields = gql`
 fragment ImportFileFields on md_import_file {
   id
	 year
   timestamp
   name
   hash
   format
   data
   status
   increases {
     id
     agreement_id
     members
   }
 }
`;

export const period_fields = gql`
  fragment PeriodFields on md_period {
    year
    locked
    totals { ...PeriodStats }
  } 
  ${period_stats_fields}
  `;

  //query period_info($order: [md_period_order_by!] = [{year: desc}, {locked: asc}]) {
export const periods_query = gql`
  query period_info {
    period:md_period(order_by: [{year: desc}]) {
         ...PeriodFields
    }
  }
  ${period_fields}
`

export class SearchPeriods extends StaticQuery {
  get gql_impl() {
    return periods_query;
  }
  data_impl({ data: { period } }) {
    return period.map(p => ({...p, id: p.year}));
  }
  query(vars) {
    vars.period = undefined;
    super.query({});
  }
}

export const import_files_query = gql`
  query import_file($period: Int!, $order: [md_import_file_order_by!]) {
    import_file:md_import_file(order_by: $order, where: {year: {_eq: $period}}) {
         ...ImportFileFields
    }
  }
  ${import_file_fields}
`

export class SearchImportFiles extends StaticQuery {
  get gql_impl() {
    return import_files_query;
  }
  data_impl({ data: { import_file } }) {
    return import_file;
  }
}



export const increase_fields = gql`
  fragment IncreaseFields on md_increase {
    id
		year
    agreement_id
		row_src
		state
		council
		local
		subunit
		employer
		master
    members
		type
		value
		base
		eff_dt
		in_negot
		contact
		notes
    updated
		agr_eff_dt
		agr_exp_dt

    g_affiliate
    src_file_hash
    src_file_row
    src_file_row_hash

	}

	`;

  //fragment IncreaseSumFields on md_v_increase_sums {
const increase_sum_fields = gql`
  fragment IncreaseSumFields on md_vm_fast_increase_sums {
    year
    agreement_id
    id
    members
    in_negot
    increase_pct
    increase_pct_adj
    total_members
    total_stat
    adjusted_members
    weighted_increase
    total_weighted
    members_in_negotiation
    total_adjusted_members
    adjusted_total_members_in_negot
    members_in_negot
    adjusted_members_in_negot
  }`;

export const local_fields = gql`
  fragment LocalFields on md_local {
    id
		aff_pk
		state
		council
		local
    name
		jurisdiction

    updated

		stat {
			year
			units
		}
  }
  `;


 export const agreement_entry_fields = gql`
  fragment AgreementEntryFields on md_agreement_entry {
    agreement_id
		year
		stat_override
    period { ...PeriodFields }
   }
  ${period_fields}
   `;


			//...LocalFields
    //increases { ...IncreaseFields }
export const agreement_fields = gql`
  fragment AgreementFields on md_agreement {
		id
		year
		is_master
		state
		council
		local
		master

		locals { 
			local {
			...LocalFields
			}
		}
    increases {
      ...IncreaseFields
      sums {
        ...IncreaseSumFields
      }
    }

		sums {
			total_members
			total_stat
			total_weighted
			members_in_negotiation
			total_adjusted_members
		}
   }
  ${increase_fields}
  ${local_fields}
  ${increase_sum_fields}
  ${period_fields}
   `;
    //period { ...PeriodFields }

export const agreement_only_fields = gql`
  fragment AgreementOnlyFields on md_agreement {
		id
		year
		is_master
		state
		council
		local
		master
	}
   `;


export const agreement_entry_detail = gql`
  fragment AgreementEntryDetail on md_agreement_entry {
    agreement_id
		year
		stat_override

		agreement {
			...AgreementOnlyFields
		}
    period { ...PeriodFields }
		increases { 
				...IncreaseFields
		}

		sums {
			total_members
			total_stat
			total_weighted
			members_in_negotiation
			total_adjusted_members
		}
		locals {
			local {
				...LocalFields
			}
		}
		increase_sums {
			id
			increase_pct
			increase_pct_adj
			members
			adjusted_members
			weighted_increase
			total_weighted
			total_members
			total_stat
			total_adjusted_members
			members_in_negotiation
		}

  }
  ${period_fields}
	${increase_fields}
	${local_fields}
	${agreement_only_fields}
  `;
/* export const master_agreement_fields = gql`
  fragment MasterAgreementFields on core_master {
    id
    name
    year
    updated
    increases { ...IncreaseFields }
    report_individually
    locals(order_by: [{local: {local: asc}}]) { 

      local {
        ...LocalFields 
      }
    }
  }
  ${increase_fields}
  ${local_fields}
`; */

/* export const unit_agreement_fields = gql`
 fragment UnitAgreementFields on core_unit {
         agreements {
           agreement {
             ...AgreementFields
           }
         }
  }
  ${agreement_fields}
`;


export const unit_info_query = gql`
  query unit_info($UnitId: Int!){
    unit:core_unit( where: {id: {_eq: $UnitId}} ){
         ...UnitFields
         ...UnitAgreementFields
    }
  }
  ${local_fields}
  ${unit_agreement_fields}
`

export const units_query = gql`
  query unit_info{
    unit:core_unit {
         ...UnitFields
    }
  }
  ${local_fields}
`; */
      /*stat(where: {_or: [{period_id: {_eq: $period}}, {_not: {period_id: {}}}]}) {
        period_id
        stat
      }*/

export const locals_search = gql`
  query locals_search($include_deprecated: Boolean=false, $limit: Int=20, $tscursor: String, $period: Int, $filter: String, $state: String, $council: Int, $local: Int, $hasmaster: Boolean, $order: [md_local_order_by!]){
    local:md_local(
      order_by: $order,
      limit: $limit,
      where: {
        _and: 
         [
           {_or: [
             {name: {_ilike: $filter}}, 
           ]},
           {state: {_ilike: $state}},
           {council: {_eq: $council}},
           {local: {_eq: $local}}
         ]
      }
      ){
         ...LocalFields
         stat(where: {_or: [{year: {_eq: $period}}, {_not: {year: {}}}]}) {
           year
           stat
         }
    }
  }
  ${local_fields}
`;

export const affiliates_search = gql`
  query affiliates_search($filter: String, $state: String, $council: Int, $local: Int, $type: String, $limit: Int = 10000, $tscursor: String, $period: Int, $order: [md_v_affs_order_by!]) {
  affiliate: md_v_affs(
      order_by: $order,
      limit: $limit,
      where: {
          _and: 
          [
            {period: {_eq: $period}}
            {_or: [ 
              {_and:
                  [
                    {id: {_ilike: $filter}},
                    {state: {_ilike: $state}},
                    {chapter: {_eq: $local}},
                    {chapter: {_eq: $unit}}
                  ]
                },
              ]},
            {_and: [{period: {_eq: $period}}] },
            {_and: [
              {type: {_eq: $type}},
              {chapter: {_eq: $local}},
              {chapter: {_eq: $council}}
            ]},
          ]
        }
    )
    {
        id
        type
        chapter
        state
        period
    }
  }
`;


/* export const master_search = gql`
  query masters_search($limit: Int=20, $tscursor: String, $period: Int!, $filter: String, $state: String, $council: Int, $local: Int, $subunit: String,  $order: [md_agreement_order_by!]){
    master:md_agreement(
      order_by: $order,
      limit: $limit,
      where: { 
        _and: [ 
          {year: {_eq: $period}}, 
          {is_master: {_eq: true}},
          {_or: [  
            {master: {_ilike: $filter}}, 
            {locals: {local: {name: {_ilike: $filter}}}}
         	]},
          {_or: [
            {_not: {locals:{}}},
            {_and: [
              {locals: {local: {state: {_ilike: $state}}}}, 
              {locals: {local: {council: {_eq: $council}}}}, 
              {locals: {local: {local: {_eq: $local}}}}
            ]}
          ]}
        ]}
      )
			id
			year
			is_master
		  name:master
    }
`; */
  //${agreement_fields}
//
export const master_search = gql`
  query masters_search( 
		$limit: Int=20,
		$tscursor: String,
		$period: Int!,
		$filter: String,
		$state: String,
		$council: Int,
		$local: Int,
		$order: [md_agreement_order_by!] ) {
    master:md_agreement(
      limit: $limit,
      order_by: $order,
			where: {
        _and: [ 
          {year: {_eq: $period}}, 
          {is_master: {_eq: true}},
          {_or: [  
            {master: {_ilike: $filter}}, 
            {locals: {local: {name: {_ilike: $filter}}}}
         	]},
          {_or: [
            {_not: {locals:{}}},
            {_and: [
              {locals: {local: {state: {_ilike: $state}}}}, 
              {locals: {local: {council: {_eq: $council}}}}, 
              {locals: {local: {local: {_eq: $local}}}}
            ]}
          ]}
        ]}
		) {
			...AgreementFields 
		}
	}
		${agreement_fields}
	`;
	

          //{increases:{}},
export const increase_search = gql`
  query increase_search(
      $limit: Int=20,
      $tscursor: String,
      $period: Int!,
      $filter: String,
      $state: String,
      $council: Int,
      $local: Int,
      $subunit: String,
      $master_only: Boolean,
      $increase_condition: md_agreement_bool_exp!={increases: {}},
      $promoted: Boolean,
      $order: [md_agreement_order_by!]
    ){
    agreement:md_agreement(
      limit: $limit,
      order_by: $order,
      where: {
        _and: 
        [ 
          {year: {_eq: $period}}
          {is_master: {_eq: $master_only}} 
          $increase_condition
          {_and: [
            {locals: {local: {state: {_eq: $state}}}}
            {locals: {local: {council: {_eq: $council}}}}
            {locals: {local: {local: {_eq: $local}}}}
            {_or: [
              {master: {_ilike: $filter}},
              {locals: {local: {name: {_ilike: $filter}}}}
              {increases: {employer: {_ilike: $filter}}}
            ]}
          ]}
        ]}
      ){
      ...AgreementFields
    }
  }
  ${agreement_fields}
`;

export const unpromoted_agreement_search = gql`
  query unpromoted_agreement_search($limit: Int=20, $tscursor: String, $period: Int!, $filter: String, $state: String, $council: Int, $local: Int, $subunit: String, $master_only: Boolean, $promoted: Boolean, $order: [cached_agreement_order_by!]){
    agreement:md_agreement_entry(
      limit: $limit,
      order_by: $order,
      where: {
        _and: 
        [ 
          {units: {unit: {deprecated: {_eq: false}}}},
          {period_id: {_eq: $period}}, 
          {master: {_eq: $master_only}}, 
          {promoted: {_eq: $promoted}}, 
          {_or: [  
            {units: {unit: {name: {_ilike: $filter}}}}
          ]}, 
          {_or: [
            {_not: {units:{}}},
            {_and: [
              {units: {unit: {state: {_ilike: $state}}}}, 
              {units: {unit: {council: {_eq: $council}}}}, 
              {units: {unit: {local: {_eq: $local}}}}, 
              {units: {unit: {subunit: {_eq: $subunit}}}}
            ]}
          ]}
        ]}
      ){
      ...AgreementEntryFields
      
      units {
        unit {
          state
          council
          local
          subunit
        }
      }
    }
  }
  ${agreement_entry_detail}
`;

export class QueryUnitsList extends StaticQuery {
  get gql_impl() {
    return units_query;
  }
  data_impl({ data: { unit } }) {
    return unit;
  }
}

export class UnitInfo extends StaticQuery {
  get gql_impl() {
    return unit_info_query;
  }
  data_impl({ data: { unit: [unit] } }) {
    return unit;
  }
}

export class SearchLocals extends StaticQuery {
  get gql_impl() {
    return locals_search;
  }
  data_impl({ data: { unit } }) {
    return unit;
  }
}

export class SearchAffiliates extends StaticQuery {
  get gql_impl() {
    return affiliates_search;
  }
  data_impl({ data: { affiliate } }) {
    return affiliate;
  }
}

export class SearchIncreases extends StaticQuery {
  network_only = true;
  get gql_impl() {
    return increase_search;
  }
  data_impl({ data: { agreement } }) {
    return agreement;
  }
}
export class SearchAgreements extends StaticQuery {
  network_only = true;
  get gql_impl() {
    return unpromoted_agreement_search;
  }
  data_impl({ data: { agreement } }) {
    return agreement;
  }
}

export class SearchMasters extends StaticQuery {
  get gql_impl() {
    return master_search;
  }
  data_impl({ data: { master } }) {
    return master;
  }
}

/*

export const upsert_unit_mutation = gql`
  mutation upsert_unit($changeMap: core_unit_insert_input!, $changeCols: [core_unit_update_column!]!) {
    unit:insert_core_unit(
      objects: [ $changeMap ],
      on_conflict: {
        constraint: core_unit_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        ...UnitFields
        all_agreements {
              agreement {
                id
                name
                promoted
                period_id
                increases {
                  ...IncreaseFields
                }
              }
            }
      }
    }
  }
  ${local_fields}
  ${increase_fields}
`;

*/
export const delete_unit_mutation = gql`
  mutation delete_unit($deleteId: Int!) {
    delete_core_unit(
      where: { id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`;


export class EditUnit extends SaveableQuery {
  friendly_name = "Unit"
  refetch_queries = refetch_searches

  get required_keys_impl() {
    return ['id', 'name'];
  }
  data_impl({ data: { unit: { returning: [d] } } }) {
    return d;
  }

  get mutate_gql_impl() {
    return upsert_unit_mutation;
  }
  get delete_gql_impl() {
    return delete_unit_mutation;
  }
  /*
  get save_queries_impl() {
    return [(new PersonNotes(null, null))];
  }*/
}


export const upsert_unit_alias_mutation = gql`
  mutation upsert_unit_alias($changeMap: core_unit_alias_insert_input!, $changeCols: [core_unit_alias_update_column!]!) {
    unit_alias:insert_core_unit_alias (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: core_unit_alias_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        id
        unit_id
        name
      }
    }
  }
`;

export const delete_unit_alias_mutation = gql`
  mutation delete_unit_alias($deleteId: Int!) {
    delete_core_unit_alias (
      where: { id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`;



/*
export class PersonNotes extends StaticQuery {
  get required_impl() {
    return ['PersonId'];
  }
  get gql_impl() {
    return person_note_for_person_query;
  }
  data_impl({ data: { person_note: person_note } }) {
    return person_note;
  }
  vars_from_impl(data) {
    return { 'PersonId': data.person_id };
  }
}*/


export class EditUnitAlias extends SaveableQuery {
  friendly_name = "Alias"
  refetch_queries = refetch_searches

  get required_keys_impl() {
    return [
      "unit_id"
    ];
  }
  data_impl({ data: { unit_alias: { returning: [d] } } }) {
    return d;
  }

  get mutate_gql_impl() {
    return upsert_unit_alias_mutation;
  }
  get delete_gql_impl() {
    return delete_unit_alias_mutation;
  }
  /*
  get save_queries_impl() {
    return [(new PersonNotes(null, null))];
  }*/
}




export const upsert_period_mutation = gql`
  mutation upsert_period($changeMap: md_period_insert_input!, $changeCols: [md_period_update_column!]!) {
    period:insert_md_period(
      objects: [ $changeMap ],
      on_conflict: {
        constraint: period_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        ...PeriodFields
      }
    }
  }
  ${period_fields}
`;

export const delete_period_mutation = gql`
  mutation delete_md_period($year: Int!) {
    delete_md_period(
      where: { year: {_eq: $year} }
    ) {
        affected_rows
      }
  }
`;


export class EditPeriod extends SaveableQuery {
  friendly_name = "Period"
  refetch_queries = refetch_searches

  get required_keys_impl() {
    return ['id', 'year'];
  }
  data_impl({ data: { period: { returning: [d] } } }) {
    return d;
  }

  get mutate_gql_impl() {
    return upsert_period_mutation;
  }
  get delete_gql_impl() {
    return delete_period_mutation;
  }
}




export const upsert_master_member_mutation = gql`
  mutation upsert_master_member($changeMap: core_master_member_insert_input!, $changeCols: [core_master_member_update_column!]!) {
    master_member:insert_core_master_member (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: core_master_member_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        master_id
        unit_id
        unit {
          ...UnitFields
        }
      }
    }
  }
  ${local_fields}
`;

export const delete_master_member_mutation = gql`
  mutation delete_master_member($unitId: Int!, $targetId: Int!) {
    delete_core_master_member (
      where: { _and: [{unit_id: {_eq: $unitId}}, {master_id: {_eq: $targetId}}] }
    ) {
        affected_rows
      }
  }
`;




export class EditMasterMember extends SaveableQuery {
  friendly_name = "Unit"
  refetch_queries = refetch_searches

  get required_keys_impl() {
    return [
      "unit_id", "master_id"
    ];
  }
  data_impl({ data: { master_member: { returning: [d] } } }) {
    return d;
  }

  get mutate_gql_impl() {
    return upsert_master_member_mutation;
  }
  get delete_gql_impl() {
    return delete_master_member_mutation;
  }
  /*
  get save_queries_impl() {
    return [(new PersonNotes(null, null))];
  }*/
}



export const upsert_increase_mutation = gql`
  mutation upsert_increase($changeMap: md_increase_insert_input!, $changeCols: [md_increase_update_column!]!) {
    increase:insert_md_increase (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: increase_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        agreement_id
        id
        ...IncreaseFields
      }
    }
  }
  ${increase_fields}
`;

export const delete_increase_mutation = gql`
  mutation delete_increase($deleteId: Int!) {
    delete_md_increase (
      where: { id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`;

export class EditIncrease extends SaveableQuery {
  friendly_name = "Increase Row"
  refetch_queries = refetch_searches

  get required_keys_impl() {
    return [
      "year", "agreement_id", "members"
    ];
  }

  data_impl({ data: { increase: { returning: [d] } } }) {
    return d;
  }

  get mutate_gql_impl() {
    return upsert_increase_mutation;
  }
  get delete_gql_impl() {
    return delete_increase_mutation;
  }
  /*
  get save_queries_impl() {
    return [(new PersonNotes(null, null))];
  }*/
}

export const insert_master_mutation = gql`
mutation insert_target(
  $id: Int!,
  $period_id: Int!,
  $name: String!,
  $members: core_master_member_arr_rel_insert_input!
  ) {
  insert_core_master ( objects: [
    {
      id: $id,
      period_id: $period_id,
      name: $name,
      members: $members
    }
   ]) {
    affected_rows
      returning {
      id
      ...AgreementFields
    }
  }
}
${agreement_fields}
`;


export const upsert_master_mutation = gql`
  mutation upsert_master($changeMap: core_master_insert_input!, $changeCols: [core_master_update_column!]!) {
    master:insert_core_master (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: core_master_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        id
        ...AgreementFields
      }
    }
  }
  ${agreement_fields}
`;

export const delete_master_mutation = gql`
  mutation delete_master($deleteId: Int!) {
    delete_core_master (
      where: {id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`;

export const delete_master_increases_mutation = gql`
  mutation delete_master_increases($targetId: Int!) {
    delete_md_increase (
      where: {agreement_id: {_eq: $targetId} }
    ) {
        affected_rows
      }
  }
`;


export class EditMaster extends SaveableQuery {
  friendly_name = "Data"
  refetch_queries = ["master_search"]

  get required_keys_impl() {
    return ["id", "name"];
  }
  data_impl({ data: { master: { returning: [d] } } }) {
    return d;
  }

  get mutate_gql_impl() {
    return upsert_master_mutation;
  }
  get delete_gql_impl() {
    return delete_master_mutation;
  }
  /*
  get save_queries_impl() {
    return [(new PersonNotes(null, null))];
  }*/
}



export const upsert_agreement_mutation = gql`
  mutation upsert_agreement_info($changeMap: core_agreement_info_insert_input!, $changeCols: [core_agreement_info_update_column!]!) {
    agreement:insert_core_agreement_info (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: core_agreement_info_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        ...AgreementEntryFields
      }
    }
  }
  ${agreement_entry_detail}
`;

export const delete_agreement_mutation = gql`
  mutation delete_agreement($deleteId: Int!) {
    delete_core_agreement_info (
      where: {agreement_id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`;

export const delete_agreement_increases_mutation = gql`
  mutation delete_agreement_increases($targetId: Int!) {
    delete_md_increase (
      where: {agreement_id: {_eq: $targetId} }
    ) {
        affected_rows
      }
  }
`;


export class EditAgreement extends SaveableQuery {
  friendly_name = "Agreement"
  refetch_queries = ["agreement_search"]

  get required_keys_impl() {
    return ["agreement_id"];
  }
  data_impl({ data: { agreement: { returning: [d] } } }) {
    return d;
  }

  get mutate_gql_impl() {
    return upsert_agreement_mutation;
  }
  get delete_gql_impl() {
    return delete_agreement_mutation;
  }
  /*
  get save_queries_impl() {
    return [(new PersonNotes(null, null))];
  }*/
}


export const upsert_import_file_mutation = gql`
  mutation upsert_import_file($changeMap: md_import_file_insert_input!, $changeCols: [md_import_file_update_column!]!) {
    import_file:insert_md_import_file (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: import_file_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        ...ImportFileFields
      }
    }
  }
  ${import_file_fields}
`;

export const delete_import_file_mutation = gql`
  mutation delete_import_file($deleteId: Int!) {
    delete_import_file (
      where: {id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`;


export class EditImportFile extends SaveableQuery {
  friendly_name = "ImportFile"
  refetch_queries = ["upload_search"]

  get required_keys_impl() {
    return [];
  }
  data_impl({ data: { import_file: { returning: [d] } } }) {
    return d;
  }

  get mutate_gql_impl() {
    return upsert_import_file_mutation;
  }
  get delete_gql_impl() {
    return delete_import_file_mutation;
  }
}

