import { LitElement, html, css } from 'lit';
import '@material/mwc-button';

import { wait, transitionEnd, animationEnd } from './utilities/anim.js';

const snackbar_timeout = 7000;
const snackbar_styles = css`
        :host {
          font-family: 'Roboto', 'Noto', sans-serif;
          --shadow-level-one: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
         --shadow-level-onepointone: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12), 0 3px 3px -2px rgba(0, 0, 0, 0.4); 
         --shadow-level-two: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.4); 
         --shadow-transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1), filter 0.28s cubic-bezier(0.4, 0, 0.2, 1);

        }

        div.snack {
          position: fixed;
          bottom: 48px;
          left: 48px;
          width: 344px;
          background-color: var(--paper-grey-700);
          color: white;
          border-radius: 5px;
          z-index: 100;
          box-shadow: var(--shadow-level-two);

          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: flex-start;
          transition: opacity 0.3s ease 0s;
          transition-timing-function: cubic-bezier(.4, 0, .2, 1);
        }
        div.snack[on] { opacity: 1; }

        div.snack[kind="error"] {
          background-color: var(--paper-red-700);
        }

        div.snack[kind="success"], div.snack[kind="complete"] {
          background-color: var(--paper-cyan-800);
        }

        div.text {
          margin: 16px;
        }
        div.action {
          margin: 8px;
        }
      `;

class KaleSnackbar extends LitElement {
  static styles = snackbar_styles

  static get properties() {
    return {
      current: { type: Object },
    };
  }
  constructor() {
    super();
    this.queue = [];
    this.current = null;
  }
  doAction(action) {
    this.current = null;
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    action();
    let t = setTimeout(() => {
      this.showNext();
    }, 200);
  }
  showSnack(s) {
    this.current = s;
    this.timeout = setTimeout(() => {
      this.current = null;
      let t = setTimeout(() => {
        this.showNext();
      }, 200);
    }, s.duration ? s.duration * 1000 : snackbar_timeout);
  }
  showNext() {
    // if (this.current) return;
    this.current = this.queue.shift();
    if (this.current) {
      this.showSnack(this.current);
    }
  }
  queueSnack(snack) {
    console.log("SNACK:", snack);
    // snack = {text, action_text, action}
    //this.queue.push(snack);
    //if (!this.current) this.showNext();
    if (this?.current?.kind === 'error') {
      this.queue.push(snack);
    } else {
      this.showSnack(snack);
    }
  }
  render() {
    const snack = this.current;
    return html`
      
      ${snack ? html`
        <div id="current_snack"  class="snack" kind=${snack.kind ? snack.kind : 'normal'}><div class="text">${snack.text}</div>${snack.action ? html`<div class="action"><mwc-button @click=${e => this.doAction(snack.action)}>${snack.action_text}</mwc-button></div>` : html``}</div>
      ` : html``}
    `;
  }

}


window.customElements.define('kale-snackbar', KaleSnackbar);
export { KaleSnackbar }
