import { LitElement, html, css, nothing } from 'lit';
import gql from 'graphql-tag';
import { client, SearchPeriods } from '../queries/queries.js';
import { installRouter } from './utilities/global_router.js';
import { COGNITO_CB_ENDPOINT } from './utilities/authmgr.js';

function fromEntries(iterable) {
  return [...iterable]
    .reduce((obj, { 0: key, 1: val }) => Object.assign(obj, { [key]: val }), {})
}

/*
window.addEventListener(
"beforeunload",
e => {
  e.preventDefault();
  console.log("before unload", e);
  window.setTimeout( () => {
    window.document.location.hash = `foo_${Math.random()}`;
  }, 0);
},
false);
*/

const cache_size = 2;


class LRUCache {
  constructor() {
    this.map = new Map();
    this.keys = [];
  }
  _hit(key) {
    this.keys = this.keys.filter(k => k !== key);
    this.keys.push(key);
    //console.log("cache hit", this.keys.slice());
    if (this.keys.length > cache_size) {
      this.map.delete(this.keys[0]);
      this.keys = this.keys.slice(-cache_size);
      // console.log("cleaned cache", this.keys.slice());
    }
  }

  get(key) {
    if (this.map.has(key)) {
      this._hit(key);
      return this.map.get(key);
    }
    return null;
  }

  set(key, val) {
    this.map.set(key, val);
    this._hit(key);
  }
  delete(key) {
    this.map.delete(key);
    this.keys = this.keys.filter(k => k!== key);
  }

  invalidateExcept(key) {
    let val = this.get(key);
    this.map = new Map();
    this.keys = [];
    if (val) this.set(key, val);
  }
}

const router_style = css`
        :host {
          --mdc-drawer-width: fit-content;
        }
       
        #drawer-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          height: 100vh;
          width: 100%;
          box-sizing: border-box;
          overflow: hidden;
          --app-drawer-selected: var(--paper-purple-200);
        }

        #drawer-nav-items {
          flex: 1 1;
          overflow: auto;
          width: 100%;
          box-sizing: border-box;
        }
        #drawer-bottom {
          flex: 0;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-end;
          height: 100%;
          width: 100%;
          box-sizing: border-box;
          background-color: #00a5dd;
          color: white;
          --mdc-theme-text-primary-on-background: white;
          --mdc-theme-text-secondary-on-background: white;
          --mdc-theme-text-icon-on-background: white;

        }

        #login-info {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          --mdc-theme-primary: white;
          --mdc-button-outline-color: white;
          --mdc-button-outline-width: 2px;
          padding-right: 12px;
          box-sizing: border-box;
        }
        #login-actions {
          display: flex;
          flex-direction: column;

        }
        #nav-list {
          flex: 1 1;
        } 
        #drawer-header {
          flex: 0;

          background-color: #00a5dd;
          background-image: 
            /* AFSCME Logo (mono) [ %3C='<' %3E='>' ]*/
            url("data:image/svg+xml,\
              %3Csvg xmlns='http://www.w3.org/2000/svg' width='317' height='264.2' viewBox='0 0 1080 900'%3E\
                %3Cg fill-opacity='0.07'%3E\
                  %3Cpolygon fill='%23444' points='90 150 0 300 180 300'/%3E\
                  %3Cpolygon points='90 150 180 0 0 0'/%3E\
                  %3Cpolygon fill='%23AAA' points='270 150 360 0 180 0'/%3E\
                  %3Cpolygon fill='%23DDD' points='450 150 360 300 540 300'/%3E\
                  %3Cpolygon fill='%23999' points='450 150 540 0 360 0'/%3E\
                  %3Cpolygon points='630 150 540 300 720 300'/%3E\
                  %3Cpolygon fill='%23DDD' points='630 150 720 0 540 0'/%3E\
                  %3Cpolygon fill='%23444' points='810 150 720 300 900 300'/%3E\
                  %3Cpolygon fill='%23FFF' points='810 150 900 0 720 0'/%3E\
                  %3Cpolygon fill='%23DDD' points='990 150 900 300 1080 300'/%3E\
                  %3Cpolygon fill='%23444' points='990 150 1080 0 900 0'/%3E\
                  %3Cpolygon fill='%23DDD' points='90 450 0 600 180 600'/%3E\
                  %3Cpolygon points='90 450 180 300 0 300'/%3E\
                  %3Cpolygon fill='%23666' points='270 450 180 600 360 600'/%3E\
                  %3Cpolygon fill='%23AAA' points='270 450 360 300 180 300'/%3E\
                  %3Cpolygon fill='%23DDD' points='450 450 360 600 540 600'/%3E\
                  %3Cpolygon fill='%23999' points='450 450 540 300 360 300'/%3E\
                  %3Cpolygon fill='%23999' points='630 450 540 600 720 600'/%3E\
                  %3Cpolygon fill='%23FFF' points='630 450 720 300 540 300'/%3E\
                  %3Cpolygon points='810 450 720 600 900 600'/%3E\
                  %3Cpolygon fill='%23DDD' points='810 450 900 300 720 300'/%3E\
                  %3Cpolygon fill='%23AAA' points='990 450 900 600 1080 600'/%3E\
                  %3Cpolygon fill='%23444' points='990 450 1080 300 900 300'/%3E\
                  %3Cpolygon fill='%23222' points='90 750 0 900 180 900'/%3E\
                  %3Cpolygon points='270 750 180 900 360 900'/%3E\
                  %3Cpolygon fill='%23DDD' points='270 750 360 600 180 600'/%3E\
                  %3Cpolygon points='450 750 540 600 360 600'/%3E\
                  %3Cpolygon points='630 750 540 900 720 900'/%3E\
                  %3Cpolygon fill='%23444' points='630 750 720 600 540 600'/%3E\
                  %3Cpolygon fill='%23AAA' points='810 750 720 900 900 900'/%3E\
                  %3Cpolygon fill='%23666' points='810 750 900 600 720 600'/%3E\
                  %3Cpolygon fill='%23999' points='990 750 900 900 1080 900'/%3E\
                  %3Cpolygon fill='%23999' points='180 0 90 150 270 150'/%3E\
                  %3Cpolygon fill='%23444' points='360 0 270 150 450 150'/%3E\
                  %3Cpolygon fill='%23FFF' points='540 0 450 150 630 150'/%3E\
                  %3Cpolygon points='900 0 810 150 990 150'/%3E\
                  %3Cpolygon fill='%23222' points='0 300 -90 450 90 450'/%3E\
                  %3Cpolygon fill='%23FFF' points='0 300 90 150 -90 150'/%3E\
                  %3Cpolygon fill='%23FFF' points='180 300 90 450 270 450'/%3E\
                  %3Cpolygon fill='%23666' points='180 300 270 150 90 150'/%3E\
                  %3Cpolygon fill='%23222' points='360 300 270 450 450 450'/%3E\
                  %3Cpolygon fill='%23FFF' points='360 300 450 150 270 150'/%3E\
                  %3Cpolygon fill='%23444' points='540 300 450 450 630 450'/%3E\
                  %3Cpolygon fill='%23222' points='540 300 630 150 450 150'/%3E\
                  %3Cpolygon fill='%23AAA' points='720 300 630 450 810 450'/%3E\
                  %3Cpolygon fill='%23666' points='720 300 810 150 630 150'/%3E\
                  %3Cpolygon fill='%23FFF' points='900 300 810 450 990 450'/%3E\
                  %3Cpolygon fill='%23999' points='900 300 990 150 810 150'/%3E\
                  %3Cpolygon points='0 600 -90 750 90 750'/%3E\
                  %3Cpolygon fill='%23666' points='0 600 90 450 -90 450'/%3E\
                  %3Cpolygon fill='%23AAA' points='180 600 90 750 270 750'/%3E\
                  %3Cpolygon fill='%23444' points='180 600 270 450 90 450'/%3E\
                  %3Cpolygon fill='%23444' points='360 600 270 750 450 750'/%3E\
                  %3Cpolygon fill='%23999' points='360 600 450 450 270 450'/%3E\
                  %3Cpolygon fill='%23666' points='540 600 630 450 450 450'/%3E\
                  %3Cpolygon fill='%23222' points='720 600 630 750 810 750'/%3E\
                  %3Cpolygon fill='%23FFF' points='900 600 810 750 990 750'/%3E\
                  %3Cpolygon fill='%23222' points='900 600 990 450 810 450'/%3E\
                  %3Cpolygon fill='%23DDD' points='0 900 90 750 -90 750'/%3E\
                  %3Cpolygon fill='%23444' points='180 900 270 750 90 750'/%3E\
                  %3Cpolygon fill='%23FFF' points='360 900 450 750 270 750'/%3E\
                  %3Cpolygon fill='%23AAA' points='540 900 630 750 450 750'/%3E\
                  %3Cpolygon fill='%23FFF' points='720 900 810 750 630 750'/%3E\
                  %3Cpolygon fill='%23222' points='900 900 990 750 810 750'/%3E\
                  %3Cpolygon fill='%23222' points='1080 300 990 450 1170 450'/%3E\
                  %3Cpolygon fill='%23FFF' points='1080 300 1170 150 990 150'/%3E\
                  %3Cpolygon points='1080 600 990 750 1170 750'/%3E\
                  %3Cpolygon fill='%23666' points='1080 600 1170 450 990 450'/%3E\
                  %3Cpolygon fill='%23DDD' points='1080 900 1170 750 990 750'/%3E\
                %3C/g%3E\
              %3C/svg%3E\
            ");


          color: white;
          width: 100%;
          padding: 12px;
          padding-top: 20%;
          box-sizing: border-box;
          font-size: 24px;

          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: flex-end;
          align-items: flex-start;
        }

        #logo {
          background-image: var(--afscme-logo-url);
          width: 100%;
          height: 28px;
          background-size: contain;
          background-repeat: no-repeat;
          position: absolute;
          top: 8px;
          opacity: 0.7;
        }

        #app_name {
          display: block;
          font-weight: 100;
        }
        #app_version {
          display: block;
          font-size: 11px;
          opacity: 0.4;
          font-weight: 100;
        }
        #extra_info {
          display: block;
          font-size: 11px;

        }

        mwc-list {
          width: 100%;
          padding-left: 8px;
          padding-right: 8px;
          box-sizing: border-box;

        }
        mwc-icon.avatar {
          font-size: 40px;
        }

        mwc-list-item {
          border-radius: 8px;
          font-weight: 100;
          --mdc-list-item-graphic-margin: 12px;
        }
        mwc-list-item.level_two {
          padding-left: 40px;
          overflow: visible;
        }

        mwc-list-item.level_two_no_icon {
          padding-left: 40px;
          overflow: visible;
          position: relative;
          --mdc-list-item-graphic-margin: 0px;
        }
        mwc-list-item.level_two mwc-icon {
          position: relative;
        }

        mwc-list-item.level_two_no_icon::before {
          display: block;
          position: absolute;
          content: ' ';
          border: 2px solid black;
          opacity: 0.2;
          border-top: none;
          border-right: none;
          width: 10px;
          height: 44px;
          left: 22px;
          top: -22px;
          border-bottom-left-radius: 4px;
        }

        mwc-list-item.level_two mwc-icon::before {
          display: block;
          position: absolute;
          content: ' ';
          border: 2px solid black;
          opacity: 0.2;
          border-top: none;
          border-right: none;
          width: 10px;
          height: 40px;
          left: -15px;
          top: -30px;
          border-bottom-left-radius: 4px;
        }

        mwc-list-item[active_route] {
          /*background-color: var(--app-drawer-selected, var(--paper-purple-200));*/
          color: var(--app-drawer-selected, var(--paper-purple-800));
          font-weight: 900;
          --mdc-theme-text-icon-on-background:  var(--app-drawer-selected, var(--paper-purple-800));
        }
        mwc-list-item:not([selected]):hover,
        mwc-list-item:not([selected]):focus {
          background-color: var(--app-drawer-selected, var(--paper-purple-100));
        }

        mwc-list-item:not([selected]):active {
          background-color: var(--app-drawer-selected, var(--paper-purple-100));
        }

        mwc-list-item[selected]:hover,
        mwc-list-item[selected]:focus {
          background-color: var(--app-drawer-selected, var(--paper-purple-100));
        }

        mwc-list-item[selected]:active {
          background-color: var(--app-drawer-selected, var(--paper-purple-100));
        }

`;


let __obj_count = 1;
const __idMap = new WeakMap();
function getObjectId(object) {
  const objectId = __idMap.get(object);
  if (objectId === undefined) {
    __obj_count += 1;
    __idMap.set(object, __obj_count);

    return __obj_count;
  }

  return objectId;
}
class KaleRouter extends LitElement {
  static styles = router_style
  constructor() {
    super();
		if (window.router) {
			this.href = window.router.href;
			this.cache = window.router.cache;
			this.current_page = window.router.current_page;
			this.active_route = window.router.active_route;
		} else {
			this.cache = new LRUCache();
			window.active_subs = [];
			window.router = this;
			this.href = window.location.href;
		}

  }
  doUnsubs() {
    window.active_subs.forEach(s => {
      s.sub.unsubscribe();
    });
    window.active_subs = [];
  }


  watchDog() {
    if (window.location.href != this.href) {
      console.warn("LOCATION WATCHDOG TRIGGERED", `this=${this.href} != window=${window.location.href}`);
      this.handleLocationChange(window.location);
    }
    // if (!this.sub) {
    //   this.subscribeToPendingUpdateCount();
    // }
    window.setTimeout(this.watchDog.bind(this), 100);
  }
  invalidate() {
    this.cache.invalidateExcept(window.location.href);
  }
  
  setParam(key, value) {
		//console.warn("SET PARAM", key, value);
    const url = new URL(window.location);
    const orig = url.searchParams.get(key);
    if (orig !== value) {
      url.searchParams.set(key, value);
      history.pushState(null, null, url);
    }
		this.href = window.location.href;
  }

  handleLocationChange(location) {
    if (location.pathname === `/${COGNITO_CB_ENDPOINT}`) {
      // ignore SSO endpoint
      return;
    }
    console.log(`ROUTER => ${location.toString()}`)
    this.href = location.href;
    let cached = this.cache.get(location.href);
    let route;

    if (cached) {
      route = cached.route;
      if (cached.elem !== this.current_page) {
        //console.log("changed route to cached", this.active, " ==> ", cached.elem);

        if (this.current_page && this.current_page.deactivateRoute) this.current_page.deactivateRoute();
        this.doUnsubs();
        this.current_page = cached.elem;
        this.active_route = cached.route;
      } else {
        //console.log("no need to change route");
      }
    } else {
      //console.log("route not in cache");
      route = this._findChildRoute(location);

      if (!route) {
        console.warn("NO ROUTE FOR", location, "REDIRECTED TO /");
        window.pushPath('/');
        return;
      }

      let search = this._searchParams(location, route);
      let canonical = route.component.constructAddress(location, search);

      if (canonical !== location.href) {
        console.log("redirecting to", canonical);
        this.navigate(canonical);
        return;
      }  

      if (this.current_page) {
        this.current_page.removeEventListener("pagetitle", this.clientPageTitle.bind(this));
        this.current_page.removeEventListener("nav-drawer", this.openNavDrawer.bind(this))
      }
      if (this.current_page && this.current_page.deactivateRoute) this.current_page.deactivateRoute();
      this.doUnsubs();

      let page = new route.component;
      this.cache.set(location.href, { route: route, elem: page, queries: [] });
      this.current_page = page;
      this.active_route = route;
      this.current_page.search = search;
    }

    this.dispatchEvent(new CustomEvent('pagetitle', { bubbles: true, composed: true, detail: { title: this.current_page.title ? this.current_page.title : null } }));
    this.current_page.addEventListener("pagetitle", this.clientPageTitle.bind(this))
    this.current_page.addEventListener("nav-drawer", this.openNavDrawer.bind(this))

		this.current_page.periods = this.periods;
		this.current_page.period_year = this.period_year;
    if (this.current_page.activateRoute) {
      console.log(`ROUTER ACTIVATING [${route.nav}] using [${this.current_page.constructor.name}@${getObjectId(this.current_page)}]${cached ? ` cached @${getObjectId(cached.elem)}`: ''}`);
      this.current_page.activateRoute(cached, route);
    }
    if (this.login) {
      this.current_page.login = this.login;
      this.current_page.active_user = this.login.user;
    }
    this.current_page.pending_updates = this.pending_update_count;
  }

	
  updated(changedProperties) {
		if (this.current_page && changedProperties.has('periods')) {
			this.current_page.periods = this.periods;
		}
		if (this.current_page && changedProperties.has('period_year')) {
			this.current_page.period_year = this.period_year;
		}
	}

  clientPageTitle(e) {
    this.dispatchEvent(new CustomEvent('pagetitle', { bubbles: true, composed: true, detail: { title: this.current_page.title ? this.current_page.title : null } }));
  }

  get pending_update_count() { return this._pending_update_count }
  set pending_update_count(c) {
    this._pending_update_count = c;
    if (this.current_page) {
      this.current_page.pending_updates = this.pending_update_count;
      this.current_page.requestUpdate('pending_updates');
    }
  }

  subscribeToPendingUpdateCount() {
    const pending_query = gql`
       subscription pending_updates {
        updated_agreements_aggregate {
          aggregate {
            count(distinct: true)
          }
        }
      }`;
    this.sub = client.subscribe({
      query: pending_query
    }).subscribe(
      {
        next: data => {
          const {data: {updated_agreements_aggregate: {aggregate: {count}}}} = data;
          this.pending_update_count = count;
        },
        error: error => {
          console.warn("SUB ERROR", error);

        }
      }
    );
    //TODO: error handling and retry here
  }


  firstUpdated() {
    //this.routes = Array.from(this.children);
    installRouter(this.handleLocationChange.bind(this));
    //window.setInterval(this.watchDog.bind(this), 500);
    window.setTimeout(this.watchDog.bind(this), 100);


  }

  _searchParams(location, route) {
    let search = {};
    if (location.search && location.search !== "") {
      search = fromEntries(new URLSearchParams(location.search).entries());
    }
    let matched_path = location.pathname.match(route.path);
    matched_path = (matched_path && matched_path.length > 1) ? matched_path[1] : undefined;
    if (matched_path) search = {...search, matched_path}
    return search
  }
  _findChildRoute(location) {
    let route = this.routes.find((c) => location.pathname.match(c.path));
    while (route && route.subroutes) {
      route = route.subroutes.find((c) => {
       return location.pathname.match(c.path);
      });
    }

    if (route) {
      return route;
    } else {
      console.warn("router: route not found:", location.pathname);
      return undefined;
    }
  }

  navigate(pathname, n, e) {
    //console.log(window.location.search, "=> navigate => ", location, n, e);
    if (e) {
      e.stopPropagation();
    }
    if (location === window.location.pathname) {
      console.error("DUPLICATE NAVIGATION:", location);
      return;
    }
    
    let url = new URL(window.location);
    url.pathname = pathname;

    //console.log("NAVIGATE =>" , url);
    window.history.pushState({/* TODO: state */}, '', url);
    this.handleLocationChange(window.location);
  }

  renavigate(location) {
    console.log("RENAVIGATE =>" ,location);
    window.history.replaceState({}, '', location);
    this.handleLocationChange(window.location);
  }

  closeAndNavigate(new_location) {
    this.cache.delete(window.location.href);
    this.navigate(new_location);
  }

  openNavDrawer() {
    this.drawer_open = true;
  }
  render() {
    const { name, version, icon } = this.app ? this.app : {};
    const { drawer_open, current_page, active_route } = this;
    return html`

     <mwc-drawer type="modal" .open=${drawer_open} @MDCDrawer:closed=${() => this.drawer_open = false}>
       ${this.drawer_open ? /* begin drawer content selective render */ html`
            <div id="drawer-content" @selected=${() => this.drawer_open = false}>
              <div id="drawer-header">
                <span id="logo"></span>
                <span id="app_name">${name}</span>
                <span id="app_version">${version}</span></div>
              <div id="drawer-nav-items">
                <mwc-list id="nav-list">
                ${this.routes.filter(r => r.section || (r.nav && !r.parent)).map(r => 
                  r.section ? html`
                  <mwc-list-item graphic="icon">
                    <span>${r.section}</span>
                    <mwc-icon slot="graphic">${r.icon}</mwc-icon>
                  </mwc-list-item>

                  ${r.subroutes.map(sub => html`
                    <mwc-list-item class="level_two_no_icon" DEST=${sub.nav} SUBNAV @request-selected=${(e) => { this.navigate(sub.nav, 'subroute', e); this.drawer_open=false; }} ?active_route=${active_route && active_route.nav === sub.nav}>
                      <span>${sub.name}</span>
                    </mwc-list-item>
                  `)}
                  ` : html`
                  <mwc-list-item graphic="icon" DEST=${r.nav} NAV @request-selected=${(e) => { this.navigate(r.nav, 'nav', e); this.drawer_open=false; }} ?active_route=${active_route && active_route.nav === r.nav}>
                    <span>${r.component.default_title}</span>
                    <mwc-icon slot="graphic">${r.component.icon}</mwc-icon>
                  </mwc-list-item>
                  ${active_route && active_route.parent && active_route.parent === r.nav ? html`
                  <mwc-list-item class="level_two" graphic="icon" selected active_route>
                    <span>${current_page.title}</span>
                    <mwc-icon slot="graphic">${active_route.icon}</mwc-icon>
                  </mwc-list-item>
                  ` : ''}
                `)}
                </mwc-list>
              </div>

              <div id="drawer-bottom">
                  <div id="login-info">
                    <mwc-list-item twoline graphic="avatar" noninteractive style="flex: 1">
                      <span>${window.authmgr.current_login.name}</span>
                      <span slot="secondary">${window.authmgr.current_login.email}</span>
                      <mwc-icon slot="graphic" class="avatar">account_circle</mwc-icon>
                    </mwc-list-item>
                    <!--
                    <mwc-icon-button icon="manage_accounts" title="Preferences" @click=${e => this.navigate('/prefs')}></mwc-icon-button> 
                  -->
                    <mwc-icon-button icon="logout" title="Logout" @click=${this.sign_out}></mwc-icon-button>
                  </div>
              </div>
            </div>` : nothing /* end drawer content selective render */}
            <div slot="appContent">
                ${current_page}
            </div>
        </mwc-drawer>
            `;
  }
  /*
                    <div id="login-actions">
                    <mwc-button outlined icon="logout">logout</mwc-button>
                    </div>
                      <mwc-list-item @request-selected=${(e) => { this.navigate('/prefs'); }} graphic="icon"><mwc-icon slot="graphic">settings</mwc-icon>preferences</mwc-list-item>
                      <mwc-list-item @request-selected=${this.sign_out} graphic="icon"><mwc-icon slot="graphic">exit_to_app</mwc-icon>sign out</mwc-list-item>
                      */
  sign_out() {
    this.dispatchEvent(new CustomEvent('signout', { bubbles: true, composed: true, detail: null }));
  }

  set routes(routes) {
    if (routes === this.routes ) {
      return;
    }
    this._routes = routes;
    let events = {}
    routes.filter(r => r.events).forEach(r => {
      r.events.forEach(e => {
        let handler = (evt) => {
          this.handleRouteEvent(e, r, evt);
        }
        events[e] = {
          route: r,
          handler
        }
        this.addEventListener(e, handler)
      });
    });
    Object.entries(this.events ?? {}).forEach(([e,r]) => {
      this.removeEventListener(e, r.handler);
    });
    this.events = events;
    this.requestUpdate('routes')
  }
  get routes() { 
    return this._routes;
  }

  async handleRouteEvent(name, route, evt) {
    console.log("handling a route event", name);
    this.navigate(route.nav);
    await this.updateComplete;
    console.log("nav complete");
    this.current_page?.handleRouteEvent?.(name, evt);
  }

  static get properties() {
    return {
      app: { type: Object },
      //routes: { type: Array },
      active_route: { type: Object, hasChanged: (newVal, oldVal) => {
        //console.warn("active_route changed", newVal, oldVal, newVal !== oldVal);
        return newVal !== oldVal;
      } },
      current_page: { type: Object, hasChanged: (newVal, oldVal) => {
        //console.warn("current_page changed", newVal, oldVal, newVal !== oldVal);
        return newVal !== oldVal; } },
      drawer_open: { type: Boolean },
			periods: { type: Array },
			period_year: { type: Number }
    };
  }
}
/*
 * <div class="nav">
        ${this.current_page ? repeat(this.routes.filter(r => r.hasAttribute('nav')), (r) => r.nav, (r, index) => html`
          <a href="${r.getAttribute('nav')}"><div class="item" active="${r.getAttribute('nav') === this.active.getAttribute('nav')}">${r.getAttribute('nav-name')}</div></a>
        `) : html``}
        </div>

   * */

window.customElements.define('kale-router', KaleRouter);
export { KaleRouter }
