//
// Timeline editor components for employment, retirement, suspension, etc.
// Inherit from a generic base class

import { html, css } from 'lit';
import '@material/mwc-button';
import '@material/mwc-textfield';
import '@material/mwc-checkbox';
import '../shared-components/form.js';
import { EventDate } from '../mindues-app/eventdate.js';
import { KaleComponent, KaleForm } from '../shared-components/form.js';

import { timeline_classes } from './colors.js';
import gql from 'graphql-tag';
// TODO: remove direct calls to client
import { client } from '../queries/queries.js';

function saveSelection() {
  var sel = window.getSelection();
  if (sel.getRangeAt && sel.rangeCount) {
    return sel.getRangeAt(0).cloneRange();
  }
}

function restoreSelection(range) {
  if (range) {
    var sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  }
}

export class ContributionDateCell extends KaleComponent {
  static styles = css`
        :host {
          position: relative;
        }
        span.placeholder {
          visibility: hidden;
          display: none;
          position: absolute;
          top:0;
          right: 50px;
          height: 0;
          width: 0;
          font-size: 75%;
          font-style: italic;
          text-transform: uppercase;
        }
        span.placeholder[placehold] {
          visibility: visible;
          display: inline-block;
          opacity: 0.25;
        }
        #input_elem[dirty] {
          font-style: italic;
          color: var(--paper-green-600); 
        }
        .nowrap {
          white-space: nowrap;
        }
        
        `

  static get properties() {
    return {
      focused: { type: Boolean },
      ...(super.properties)
    }
  }
  render() {
    return html`<style>${this.styles}</style><span class="placeholder" ?placehold=${!this.focused && !this.dirty && !this._value === undefined && !this.value === null} >${this._field}</span><div id="input_elem" ?dirty=${this.dirty} @input=${e => this.input_change(e)} class="nowrap editable datecontent" contenteditable @blur=${e => this.focused = false} @focus=${e => this.focused = true}></div>`
  }

  set field(f) { this._field = f; }
  get field() { return `${this.contrib_id}::${this._field}` }

  firstUpdated() {
    super.firstUpdated();
    if (this.elem) this.elem.innerText = this.format(this._value);
    this.scrollIntoView();
  }
  set value(val) {
    val = val === undefined ? null : val;
    val = this.transformInput(val);
    let oldVal = this._orig;
    if (val !== oldVal) {
      val = val === 'actual_null_request' ? null : val;
      this._value = val;
      this._orig = val;
      this._send_events = false;
      this.updateComplete.then(() => {
        this._send_events = true;
      });
      if (this.elem) this.elem.innerText = this.format(this._value);
    } else {
      this._orig = val;
    }
  }
  format(val) {
    const na = /^\w*[nN]\/*[aA]\s*/;
    return val !== null && val !== undefined && !na.test(val) ? new EventDate(val) : null;
  };

  get value() {
    return this._value;
  }

  formatInput(val) {
    return val;
  }
  async input_change(evt) {
    let elem = evt.path[0];

    console.log("input_change", evt);
    console.log("elem:", evt.path[0]);

    let content = this.formatInput(elem.innerText);
    this.valueChange(content);
    console.log(`${this.constructor.name}[${this.field}] input: "${content}" value=${this.value}`);
  }
}
export class ContributionAmtCell extends ContributionDateCell {
  render() {
    return html`<span style="float: left; opacity: 0.8;">$</span><span class="placeholder" ?placehold=${!this.focused && !this.dirty && !this._value === undefined && !this.value === null}>${this._field}</span><div ?dirty=${this.dirty} id="input_elem" @input=${e => this.input_change(e)} class="nowrap editable amtcontent" contenteditable @blur=${e => this.focused = false} @focus=${e => this.focus()} ></div>`
  }
  //format(val) { return val ? (Math.round(val*100)/100).toLocaleString([], {style: 'currency', currency: 'USD'}) : val });
  format(val) {
    return val ? (Math.round(val * 100) / 100) === 0 ? null : (Math.round(val * 100) / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '–';
  }
  focus() {
    this.focused = true;
    window.setTimeout(() => {
      var sel, range;
      if (window.getSelection && document.createRange) {
        range = document.createRange();
        range.selectNodeContents(this.elem);
        sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      } else if (document.body.createTextRange) {
        range = document.body.createTextRange();
        range.moveToElementText(this.elem);
        range.select();
      }
    }, 1);
  }

  formatInput(val) {
    return val.replace(/,/, '');
  }
}

export class ContributionNumberCell extends ContributionDateCell {
  render() {
    return html`<span class="placeholder" ?placehold=${!this.focused && !this.dirty && !this._value === undefined && !this.value === null}>${this._field}</span><div ?dirty=${this.dirty} id="input_elem" @input=${e => this.input_change(e)} class="nowrap editable amtcontent" contenteditable @blur=${e => this.focused = false} @focus=${e => this.focused = true} ></div>`
  }
  format(val) { return val };
}


export class ContributionTextCell extends ContributionDateCell {
  render() {
    return html`<span class="placeholder" ?placehold=${!this.focused && !this.dirty && !this._value === undefined && !this.value === null}>${this._field}</span><div ?dirty=${this.dirty} id="input_elem" @input=${e => this.input_change(e)} class="editable amtcontent" contenteditable @blur=${e => this.focused = false} @focus=${e => this.focused = true} ></div>`
  }
  format(val) { return val };
}

export class ContributionEnumCell extends ContributionDateCell {
  render() {
    return html`<span class="placeholder" ?placehold=${!this.focused && !this.dirty && !this._value === undefined && !this.value === null}>${this._field}</span><div ?dirty=${this.dirty} id="input_elem" @input=${e => this.input_change(e)} class="editable amtcontent" contenteditable @blur=${e => this.focused = false} @focus=${e => this.focused = true} ></div>`
  }
  format(val) { return val };
}

export class ContributionSSNCell extends ContributionDateCell {
  render() {
    return html`<span class="placeholder" ?placehold=${!this.focused && !this.dirty && !this._value === undefined && !this.value === null}>${this._field}</span><div ?dirty=${this.dirty} id="input_elem" @input=${e => this.input_change(e)} class="nowrap editable amtcontent" contenteditable @blur=${e => this.focused = false} @focus=${e => this.focused = true} ></div>`
  }

  format(val) {
    const digits_only = /\D/g
    if (val === null || val === undefined || !typeof (val) === 'string') { return val }
    let digits = val.replace(digits_only, '');
    let out = digits;
    if (digits.length > 5) {
      out = digits.slice(0, 3) + '‑' + digits.slice(3, 5) + '‑' + digits.slice(5);
    } else if (digits.length > 3) {
      out = digits.slice(0, 3) + '‑' + digits.slice(3);
    }
    return out;
  }
}


export class ContributionBoolCell extends ContributionDateCell {
  render() {
    return html`
      <mwc-checkbox .value=${this.value} .checked=${this.value} @check-change=${e => this.valueChange(e.detail.checked)}></mwc-checkbox>
      `
  }
  format(val) { return val };
}


window.customElements.define('contrib-textcell', ContributionTextCell);
window.customElements.define('contrib-enumcell', ContributionEnumCell);
window.customElements.define('contrib-ssncell', ContributionSSNCell);
window.customElements.define('contrib-boolcell', ContributionBoolCell);
window.customElements.define('contrib-datecell', ContributionDateCell);
window.customElements.define('contrib-numbercell', ContributionNumberCell);
window.customElements.define('contrib-amtcell', ContributionAmtCell);