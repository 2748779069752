import { LitElement, html, css } from 'lit';
import '@material/mwc-icon';

const circle_style = css`
      :host {
        --mdc-theme-primary: var(--progress-color, white);
        --color: var(--progress-color, white);
        margin-right: 8px;
        --progress-size: 18;
        --mdc-icon-size: calc( var(--progress-size) * 1px);
      }

      svg {
        transform: rotate(-90deg) scale(calc( var(--progress-size) / 24 ));
      }
      circle {
        stroke: var(--progress-bg, #e6e6e6);
        opacity: 0.5;
      }
      circle.progress {
        opacity: 1;
        stroke: var(--progress-color);
        stroke-dasharray: calc(6.28px * 10);

        stroke-dashoffset: 0px;
        /*stroke-dashoffset: calc(6.28px * 7 * (1 - var(--progress)));*/

        animation-duration: 1s;
        animation-name: progress-cycle;
        animation-iteration-count: infinite;
      } 

      @keyframes progress-cycle {
        from {
           --progress: 0;
          stroke-dashoffset: calc(6.28px * 10);
           }
        to {
          --progress: 1;
          stroke-dashoffset: 0px;
        }
      }
`;

class ProgressCircle extends LitElement {
  static styles = circle_style
  constructor() {
    super();
  }
  static get properties() {
    return {
      icon_incomplete: { type: String },
      icon_complete: { type: String },
      status: { type: String }
    };
  }
  render_icon() {
    switch (this.status) {
      case 'incomplete':
      case undefined:
        return html`<mwc-icon>${this.icon_incomplete ? this.icon_incomplete : 'cloud_queue'}</mwc-icon>`;
      case 'complete':
        return html`<mwc-icon>${this.icon_complete ? this.icon_complete : 'cloud_done'}</mwc-icon>`;
      case 'animate':
        return html`
          <svg width="32" height="32" viewBox="0 0 32 32">
              <circle cx="16" cy="16" r="10" fill="none"  stroke-width="2" />
              <circle  class="progress" cx="16" cy="16" r="10" fill="none" stroke-width="2"/>
          </svg>`
    }
  }
  render() {
    return html`${this.render_icon()}`;
  }
}
window.customElements.define('progress-circle', ProgressCircle);
export { ProgressCircle }
