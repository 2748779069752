import { LitElement, html, css, nothing } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { timeline_colors, transitions } from "./colors.js";
import { shadows, colors } from "../shared-components/styles.js";
import '@material/mwc-dialog';
import '@material/mwc-textarea';
//const todate = d => d ? (new Date(d)).toISOString().slice(0, 10).replace(/-/g, '‑') : null;
const todate = d => d ? (new Date(d)).toLocaleDateString() : null;
const tomoney = (amt) => amt !== null && amt !== undefined && typeof (amt) === 'number' ? amt.toLocaleString([], { style: 'currency', currency: 'USD' }) : '$UNKNOWN';
// SELECT pid, pg_terminate_backend(pid) FROM pg_stat_activity WHERE state = 'active' and query not ilike '%MAGIC%';

const text_to_like = arg => `%${String(arg).toLowerCase().replace(/[^a-z0-9 ]/g, '').split(' ').join('%')}%`;
const text_to_number = arg => { let a = arg.replace(/[^0-9.]/g, ''); let n = a !== '' ? Number(a) : undefined; return n === undefined || isNaN(n) ? undefined : n; };
const text_to_date = arg => new Date(arg).toLocaleDateString();
const date_to_text = val => val && val.getYear ? val.toLocaleDateString() : val;
const like_to_text = val => val.replace(/%/g, ' ').trim();

export const coltypes = {
  person_link: { diff: false, text: true },
  date: { diff: true, number: false, ordered: true },
  number: { diff: true, number: true, ordered: true },
  int: { diff: true, number: true, ordered: true },
  money: { diff: true, number: true, ordered: true },
  decimal: { diff: true, number: true, ordered: true },
  pct: { diff: true, number: true, ordered: true },
  ltext: { diff: false, text: true },
  ctext: { diff: false, text: true },
  match: { diff: false, text: false },
}


const format_ssn = ssn => {
  ssn = ssn.replace(/[^\d]/g, '');
  return ssn.replace(/(\d\d\d)(\d\d)(\d*)/g, "$1-$2-●●●●");
}

export const formatters = {
  person_link: (celldata, fieldinfo, row) => html`<a href="/people/view?person=${row.id}">${celldata}</a>`,
  date: celldata => todate(celldata),
  number: celldata => Number(celldata).toLocaleString(),
  int: celldata => celldata,
  ssn: celldata => `●●●-●●-${celldata}`,
  money: celldata => celldata ? tomoney(celldata) : celldata,
  decimal: (celldata, fieldinfo) => celldata ? Number(celldata).toLocaleString(undefined, {minimumFractionDigits: fieldinfo?.decimals !== undefined ? fieldinfo?.decimals : 4}) : celldata,
  pct: (celldata, fieldinfo) => celldata !== null && celldata!==undefined ? `${Number(celldata).toLocaleString(undefined, {minimumFractionDigits: fieldinfo?.decimals !== undefined ? fieldinfo?.decimals : 4})}%` : celldata,
  text: celldata => celldata,
  ltext: celldata => celldata,
  ctext: celldata => celldata
}

export const XLSX_NULL = { v: null, t: 'z' };
export const xlsx_formatters = {
  date: celldata => {
    let str = todate(celldata);
    return celldata !== null && celldata!==undefined ? { v: celldata, t: 'd', z: 'mm-dd-yyyy' } : XLSX_NULL;
  },
  ssn: celldata => {
    return celldata !== null && celldata!==undefined ? { v: `•••-••-${celldata}`, t: 's' } : XLSX_NULL;
  },
  number: celldata => {
    return celldata !== null && celldata!==undefined ? { v: celldata, t: 'n',  z: '#,##0' } : XLSX_NULL;
  },
  int: celldata => {
    return celldata !== null && celldata!==undefined ? { v: celldata, t: 'n' } : XLSX_NULL;
  },
  money: celldata => {
    return celldata !== null && celldata!==undefined ? { v: celldata, t: 'n', z: '[$$-409]#,##0.00;[RED]-[$$-409]#,##0.00' } : XLSX_NULL;
  },
  decimal: (celldata, fieldinfo) => {
    return celldata !== null && celldata!==undefined  ? { v: celldata, t: 'n',  z: '#,##0.0000' } : XLSX_NULL;
  },
  pct: (celldata, fieldinfo) => {
    return celldata !== null && celldata!==undefined ? { v: celldata/100, t: 'n', z: '0.0000%' } : XLSX_NULL;
  },
  text: celldata => {
    return celldata !== null && celldata!==undefined ? { v: celldata, t: 's', s: {alignment: {wrapText: true}}} : XLSX_NULL;
  },
  ltext: celldata => {
    return celldata !== null && celldata!==undefined ? { v: celldata, t: 's', s: {alignment: {wrapText: true}}} : XLSX_NULL;
  },
  ctext: celldata =>{
    return celldata !== null && celldata!==undefined ? { v: celldata, t: 's', s: {alignment: {wrapText: true}}} : XLSX_NULL;
  }
}

const column_header_style = css`
        :host {
          ${transitions}
          ${timeline_colors}
          ${shadows}
          width: 100%;
        }

        div.dropdown-wrap {
          position: absolute;
          top: 100%;
          left: 0;
          height: fit-content;
          overflow: visible;
         /* padding: 100vw;
          margin-left: -100vw;
          */
          padding-top: 0px;
          /*
          padding-top: 5px;
          margin-top: -15px;
          background-color: rgba(0, 0, 0, 0.1);
          background-color: rgba(200, 40, 40, 0.2);
          */
          transform: translateY(-120%);
          transition: translate 0s jump-end;
          transition-delay: 0.3s;
        }
        div.dropdown-wrap[show] {
          transform: translateY(0);
          transition: translate 0s jump-end;
          transition-delay: 0s;
        }
        div.dropdown { 
          background-color: white;
          border-radius: 6px;
          opacity: 0;
          transform: translateY(-120%);
          box-shadow: var(--shadow-elevated);
          padding: 10px;
          transition: var(--transform-transition), var(--opacity-transition);
          transition-duration: 0.15s;
          text-align: left;
          white-space: normal;
          text-transform: none;
        }
        div.dropdown[show] {
          opacity: 1;
          transform: translateY(0);
          z-index: 202;
        }

        .wrap > mwc-icon {
          opacity: 0.5;
          font-size: 1.5em;
        }
        .wrap > span {
        }

        div.wrap {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: row; 
          transform: translate(0,0);
          white-space: nowrap;
        }


        /*
        div.wrap[compare] {
          padding: 1em;
          padding-left: 2.5em;
        }*/
        div.wrap[show] {
          z-index: 201;
        }
        .wrap.money, .wrap.int, .wrap.number, .wrap.decimal, .wrap.pct, .wrap.date {
          justify-content: flex-end;
        }

        .wrap.ctext {
          justify-content: center;
        }
        .wrap.ltext, .wrap.ssn {
          justify-content: flex-start;
        }

        .scrim {
          position: fixed;
          top: 100%;
          left: 0;
          width: 100vw;
          height: 100vh;
          background-color: black;
          opacity: 0.4;
          z-index: 200;
        }
        h5 {
          display: block;
          white-space: nowrap;
        }
        mwc-icon {
          position: relative;
          top: .25em;
          margin-top: -.25em;
        }
        .sort {
          font-weight: 100;
          display: inline-block;
          background-color: none;
          border: 1px solid var(--paper-grey-700);
          border-radius: 12px;
          padding: 0px 8px 6px 6px;
          margin: 4px;
          color: var(--paper-grey-700);
          white-space: nowrap;

        }
        .sort[active] {
          font-weight: 800;
          color: white;
          border: 1px solid var(--paper-green-700);
          background-color: var(--paper-green-700);
          }
        .sort > mwc-icon {
          margin-right: 6px;
          opacity: 0.5;
        }
        .sort[active] > mwc-icon {
          opacity: 1;
        }
        .sort > mwc-icon[reverse] {
          transform: scaleY(-1);
        }

        .filter {
          white-space: nowrap;
          width: 250px;
        }


        .title {
          font-weight: 100;
          /*color: var(--paper-grey-600);*/
          color: var(--header-color, var(--paper-grey-100));
        }
        .title[active] {
          font-weight: 900;
        }
        span.direction {
          font-weight: 100;
          font-size: 80%;
          position: relative;
          top: -0.1em;
          margin-left: 1em;
        }
        .section {
          position: relative;
          padding: 8px;
          border: 1px solid var(--paper-grey-400);
          border-radius: 6px;
          margin: 8px;
        }

        .section > h6 {
          position: absolute;
          top: calc(-2em - 11px);
          left: 1em;
          background-color: white;
          padding: 3px;
        }

        .radio-group {
          white-space: normal;
        }

        div.actions {
          width: 100%;
          text-align: right;
        }

        .reveal {
          transition: var(--transform-transition);
        }
        .reveal[revealed] {
          transform: rotate(-180deg);
        }


        mwc-menu {
          position: fixed;
          top: 1em;
          left: 0;
        }
`;
export class ColumnHeader extends LitElement {
  static styles = column_header_style
  constructor() {
    super();
    this.filter_temp = new Map();
    this.sort_temp = new Map();
  }
  static get properties() {
    return {
      def: { type: Object },
      show: { type: Boolean },
      sort: { type: Array },
      filter: { type: Array }
    };
  }

  set def(d) {
    this.__def = d;
    this.__cols = d.compare ? ['sys', 'ref', 'diff', 'match'].map(c => `${d.c}_${c}`) : [d.c];
    this.__col_set = new Set(this.__cols);
    this.requestUpdate('def');
  }
  get def() { return this.__def; }

  set sort(s) {
    this.__sort = s;
    this.__sort_active = s ? this.__col_set && s.some(s => this.__col_set.has(s.col)) : [];
    this.requestUpdate('sort');
  }
  get sort() { return this.__sort; }

  set filter(f) {
    this.__filter = f;
    this.__filter_active = f ? this.__col_set && f.some(f => this.__col_set.has(f.col)) : [];
    this.requestUpdate('filter');
  }
  get filter() { return this.__filter; }

  get active() { return this.__filter_active || this.__sort_active; }

  firstUpdated() {
    //window.addEventListener('toggle-header', e => { console.log("got toggle", e.detail, this.def.c); if (e.detail !== this.def.c) this.show = false });
    this.addEventListener('click', e => this.toggleShow(e));
  }
  

    renderMenu() {

    }

    renderPage() {
        return html``
    }

    showMenu() {
        const menu = this.renderRoot.getElementById('menu');
        menu.anchor = this.renderRoot.getElementById('anchor');
        console.log("SHOW", menu, menu.anchor);
        menu.show();
    }
    hideMenu() {
        const menu = this.renderRoot.getElementById('menu');
        console.log("HIDE", menu);
        menu.close();
    }

  toggleShow(e) {
    e.stopPropagation();
    this.show = !this.show;

    if (this.show) {
      console.log("showing")
      this.showMenu();
    } else { 
      console.log("hiding")
      this.hideMenu();
    }
  
    this.filter_temp = new Map();
    this.sort_temp = new Map(this.sort.filter(s => this.__col_set.has(s.col)).map(s => [s.col, { col: s.col, reverse: s.reverse }]));
    //sort: [{ col: 'group', reverse: false }, { col: 'name', reverse: false }, { col: 'person.id', reverse: false }],
    // if (this.show) this.dispatchEvent(new CustomEvent('show-header', { detail: { col: this.def.c, hide: (c) => { if (c !== this.def.c) this.hideMenu(); } } }));
  }
    /*
            return html`<mwc-list-item @request-selected=${action} graphic="icon">${icon ? html`<mwc-icon slot="graphic">${icon}</mwc-icon>` : ''}${name}</mwc-list-item>`

      <div class="dropdown-wrap" ?show=${this.show}>
        <div class="dropdown" ?show=${this.show} @click=${e => e.stopPropagation()}>
        <div class="section">
          <h6>sort by</h6>
          ${sorts.map(s => this.renderSort(s))}
        </div>
        <div class="section">
          <h6>filter</h6>
          ${filters.map(f => this.renderFilter(f))}
        </div>
        <div class="actions">
          <mwc-button @click=${e => this.applyOpts()}>apply</mwc-button>
        </div>
      </div>


<div class="section">
          <h6>sort by</h6>
          ${sorts.map(s => this.renderSort(s))}
        </div>
        <div class="section">
          <h6>filter</h6>
          ${filters.map(f => this.renderFilter(f))}
        </div>
        <div class="actions">
          <mwc-button @click=${e => this.applyOpts()}>apply</mwc-button>
        </div>
    */

  render() {
    let sorts = this.show ? this.getSorts() : [];
    let filters = this.show ? this.getFilters() : [];
    return html`
    <div class=${`wrap ${this.def.t}`} ?compare=${this.def.compare} ?show=${this.show} >
      <span id="anchor" class=${`title ${this.def.t}`} ?active=${this.active}>${this.def.a ? this.def.a : this.def.c}</span>
      <mwc-icon class="reveal" ?revealed=${this.show}>arrow_drop_down</mwc-icon>
    </div>
    <mwc-menu absolute corner="BOTTOM_START" id="menu" @close=${e => console.log("closed")}>
    ${sorts.map(s => html`
    <mwc-list-item graphic="icon">
      <mwc-icon slot="graphic">check</mwc-icon>
      <span>Sort</span>
    </mwc-list-item>
    `)}
    <li divider role="separator"></li>
    ${filters.map(f => html`
    <mwc-list-item graphic="icon">
      <mwc-icon slot="graphic">check</mwc-icon>
      <span>Filter</span>
    </mwc-list-item>
    `)}
        
      </mwc-menu>
    `
  }
  //<h5><mwc-icon>filter_list</mwc-icon>filter</h5>
  //${this.show ? html`<div class="scrim" @click=${e => this.show = false}></div>` : html``}
  setSort(e, col) {
    e.stopPropagation();
    //let actives = this.getSorts.filter(s => s.col !== col)
    let s = this.sort_temp.get(col);
    s = s ? s.reverse : undefined;
    let rev;
    switch (s) {
      case undefined:
        rev = false;
        break;
      case false:
        rev = true;
        break;
      case true:
        rev = undefined;
        break;
    }
    this.sort_temp.set(col, { col: col, reverse: rev });
    //this.dispatchEvent(new CustomEvent('add-sort', { detail: col }));
    this.requestUpdate("sort");
  }
  setFilter(col, op, val) {
    console.log("SETTING FILTER", col, op, val);
    //filter: [{ col: 'benefit_start_match', op: '_eq', val: false }],
    this.filter_temp.set(`${col}:::${op}`, { col: col, op: op, val: val });
  }
  applyOpts() {
    //this.dispatchEvent(new CustomEvent('add-sort', { detail: col }));
    this.dispatchEvent(new CustomEvent('col-opts', { composed: true, detail: { sort: Array.from(this.sort_temp.values()), filter: Array.from(this.filter_temp.values()) } }));
    this.filter_temp = new Map();
    this.sort_temp = new Map();
    this.show = false;
  }

  getColFilters({ col, title, coltype }, use = true) {
    if (!use) return [];
    let active = this.filter ? this.filter.filter(f => f.col === col) : [];
    let ret = [
      {
        col: col,
        coltype: coltype,
        title: title,
        op: '_eq',
        op_title: 'matches',
        arg: arg => arg,
        control: '3check',
        icon: 'check',
        use: coltype === 'match'
      },
      {
        col: col,
        coltype: coltype,
        title: title,
        op: '_ilike',
        op_title: 'contains',
        arg: text_to_like,
        rarg: like_to_text,
        control: 'text',
        icon: 'search',
        use: coltypes[coltype].text
      },
      {
        col: col,
        coltype: coltype,
        title: title,
        op: '_lt',
        op_title: 'less than',
        arg: coltype === 'date' ? text_to_date : text_to_number,
        rarg: coltype === 'date' ? date_to_text : undefined,
        control: 'text',
        icon: 'keyboard_arrow_left',
        use: coltypes[coltype].ordered
      },
      {
        col: col,
        coltype: coltype,
        title: title,
        op: '_gt',
        op_title: 'at least',
        arg: coltype === 'date' ? text_to_date : text_to_number,
        rarg: coltype === 'date' ? date_to_text : undefined,
        control: 'text',
        icon: 'keyboard_arrow_right',
        use: coltypes[coltype].ordered
      },

    ].filter(f => f.use);
    ret.forEach(r => {
      let val = active.find(a => a.op === r.op);
      if (val) {
        r.val = r.rarg ? r.rarg(val.val) : val.val;
      }

    });
    return ret;
  }
  renderFilter({ col, coltype, title, op, arg, op_title, control, icon, val }) {
    return html`
    <div class="filter">
      ${ control === 'text' ? html`
          <mwc-icon>${icon}</mwc-icon>
          <mwc-textfield
            label=${title + ' ' + op_title} 
            .value=${val !== undefined ? val : ''}
            @value-changed=${e => this.setFilter(col, op, arg(e.detail.value))}
          ></mwc-textfield>` : html``}
      ${ control === '3check' ? html`
            <div class="radio-group">
              <mwc-formfield label="matches"><mwc-radio @change=${e => this.setFilter(col, op, true)} .checked=${val === true}></mwc-radio></mwc-formfield>
              <mwc-formfield label="non-matches"><mwc-radio @change=${e => this.setFilter(col, op, false)} .checked=${val === false}></mwc-radio></mwc-formfield>
              <mwc-formfield label="either"><mwc-radio @change=${e => this.setFilter(col, op, undefined)} .checked=${val === undefined}></mwc-radio></mwc-formfield>
            </div>
          ` : html``}

    </div>`;
  

  }
  renderSort({ col, coltype, title }) {
    let s = this.sort_temp.get(col);
    let active = s ? s.reverse !== undefined : false;
    let reverse = s ? s.reverse === true : false;

    return html`<div class="sort" @click=${e => this.setSort(e, col)} ?active=${active}>
    <mwc-icon ?reverse=${reverse}>sort</mwc-icon>
    <span class="sort-title">${title}</span>
    ${active ? html`<span class="direction">${reverse ? 'desc' : 'asc'}</span>` : html``}
    </div>`;
    //${active ? (reverse ? ' desc' : ' asc') : ''}
  }

  getFilters() {

    if (this.def.compare) {
      return [
        ...this.getColFilters({ col: this.def.c + '_match', coltype: 'match', title: 'match' }),
        ...this.getColFilters({ col: this.def.c + '_sys', coltype: this.def.t, title: 'new' }),
        ...this.getColFilters({ col: this.def.c + '_ref', coltype: this.def.t, title: 'old' }),
        ...this.getColFilters({ col: this.def.c + '_diff', coltype: this.def.t, title: 'gap' }, coltypes[this.def.t].diff),
      ];
    } else {
      return [...this.getColFilters({ col: this.def.c, coltype: this.def.t, title: this.def.c })];
    }
  }
  getSorts() {
    if (this.def.compare) {
      let [sys, ref, diff] = [
        this.sort.find(s => s.col === this.def.c + '_sys'),
        this.sort.find(s => s.col === this.def.c + '_ref'),
        this.sort.find(s => s.col === this.def.c + '_diff')
      ]
      return [
        //sort: [{ col: 'group_sys', reverse: false }, { col: 'name', reverse: false }, { col: 'id', reverse: false }],
        { col: this.def.c + '_sys', active: sys, coltype: this.def.t, title: 'new', reverse: sys && sys.reverse, use: true },
        { col: this.def.c + '_ref', active: ref, coltype: this.def.t, title: 'old', reverse: ref && ref.reverse, use: true },
        { col: this.def.c + '_diff', active: diff, coltype: this.def.t, title: 'gap', reverse: diff && diff.reverse, use: coltypes[this.def.t].diff }
      ].filter(s => s.use);
    } else {
      let s = this.sort.find(s => s.col === this.def.c);
      return [{ col: this.def.c, active: s, coltype: this.def.t, title: this.def.c, reverse: s && s.reverse }];
    }
  }

}
//this.dispatchEvent(new CustomEvent('add-sort', { detail: col }));
window.customElements.define('column-header', ColumnHeader);


const report_table_style = css`
    td[editable] {
      cursor: pointer;
    }
    .editable_cell {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row; 
      width: 100%;
      height: 100%;
      position: relative;
    }
    .editable_cell > mwc-icon {
      visibility: hidden;
      position: fixed;
      right: 0;
    }

    .editable_cell > .editable_content {
      min-width: 150px;
      min-height: 40px;
      border: 2px solid transparent;
      border-radius: 5px;
      padding: 6px;
    }
    mwc-textarea {
      width: min(500px, 90vw);
      min-height: 200px;
    }


    .editable_cell > mwc-icon {
      visibility: hidden;
    }
    td[editable]:hover div.editable_content {
      border: 2px solid blue;
      border-radius: 5px;
    }
    td[editable]:hover  mwc-icon {
      visibility: visible;

    }


        .table-container {
          box-sizing: border-box;
          width: 100%;
          position: relative;
          overflow-x: auto;
          max-height: 100%;
          overflow-y: overlay;
          height: calc(100vh - 64px);
          margin: 0px;
        }
        .table-scroller {
          height: fit-content;
          width: fit-content;
          min-width: 100%;
          height: 50vh;
          background-color: white; 
        }
        table { white-space: nowrap; border-collapse: collapse; width: 100%; min-width: 100%; padding-bottom: 1em;}
        td,th { border: none; padding: 0px;}
        td[firstcol],th:first-of-type  { padding-left: calc(10px + 1.5em);}
        /*td:first-of-type,th:first-of-type  { padding-left: calc(10px + 1.5em);}*/
        td[lastcol],th:last-of-type  { padding-right: calc(10px + 1.5em);}
        th {
          font-size: 80%;
          font-weight: 100;
          text-align: left;
          text-transform: uppercase;
          z-index: 2;
          position: sticky;
          top: 0px;
          background-color: white;
          cursor: pointer;
          color: var(--header-color, var(--paper-grey-100));
          /*background-color: var(--header-bg, var(--paper-blue-700));*/
          background-color: var(--reporting-secondary, --paper-blue-700);
          padding: 12px;
        }
        
        tr { border: none}
        tr:nth-child(4n+1), tr:nth-child(4n+2) { background: #CCC;}

        /*
        td[comparei] {
          padding-right: 2.5em;
          padding-left: 2.5em;
        }
        */

        td {
          padding: 10px;
        }

        td.compare_label { 
          padding-right: 0px;
        }
        td[mismatch] {
          font-weight: bold;
        }

        td.sys_value {
          padding-bottom: 0px;
        }
        td.ref_value {
          padding-top: 0px;
        }


        td.remove { padding: 10px 0px;}
        td.date { 
        }
        td.null {
          font-size: 8px;
          color: var(--paper-grey-400);
        }

        td.money, td.int, td.number, td.decimal, td.pct, th.pct, th.money, th.int, th.number, th.decimal, td.date, th.date {
          text-align: right;
        }
        td.money, td.int, td.number, td.decimal, td.date {
          font-family: 'Courier New', Courier, monospace;
        }

        td.ltext, td.ssn { 
          text-align: left;
          white-space: normal;	
          max-width: 25%;
        }
        td.ctext { 
          text-align: center;
        }


        .summary_row {

        }
        tfoot {
        }
        tfoot td {
          padding-top: 12px;
          padding-bottom: 12px;
          font-size: 110%;
          font-weight: 100;
          background-color: var(--reporting-secondary, --paper-blue-700);
          color: white;

          position: sticky;
          bottom: 0;
        }
        span.currency {
          float: left;
        }
        span.alert {color: var(--alert-color); font-weight: 100;}
        span.alert {color: var(--alert-color)}
        span.migration {color: var(--migration-color)}
        span.error {color: var(--error-color); font-weight: 900;}


        #watcher-holder {
          height: 0;
          overflow: visible;
          position: relative;
          visibility: hidden;
        }

        .container[show] #watcher-holder {
          visibility: visible;
        }
        #watcher {
          height: 100vh;
          position: relative;
          bottom: 100vh;
          z-index: -10;
          /*
          bottom: 100vh;
          margin-bottom: -100vh;
          z-index: -5;
          */
        }
        
        th.money > .head, th.number > .head, th.decimal > .head {
        }
        .money-container {
          position: relative;
          display: inline-block;
          width: calc(100% - 1em);
          max-width: 7em;
          /*
          display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row; 
        padding-left: 5em;*/
        }
        .currency-symbol {
          font-size: 60%;
          opacity: 0.8;
          position: absolute;
          top: 0;
          left: -1em;
          /*
          flex: 1 1;
          width: 100%;
          display: inline-block;
          text-align: left;*/
        }
        .status_overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          background-color: rgba(64,64,64,0.4);
          align-items: center;
          justify-content: center;
          flex-direction: column; 
          display: none;
          z-index: -10;

        }
        .status_overlay[show] {
          display: flex;
          z-index: 10;
        }

        .status_overlay > span {
          color: white;
            font-size: 130%;
            font-weight: bold;
            padding: 5px 10px;
            margin-top: 40px;
            display: block;
        }
        .processing_status {
         position: fixed;
          bottom: 20px;
          right: 40px;
          z-index: 20;
          font-size: 120%;
          background-color: rgba(100,100,100,0.7);
          color: white;
          padding: 5px 10px;
          border-radius: 10px;
          opacity: 0;
        }

        td[mismatch] {
        }
        .comparison {
          font-weight: 700;
          white-space: nowrap;
          display: inline-block;
          width: 100%;
        }
        .comparison > * {
          position: relative;
        }


        .comparison > *::after {
          font-stretch: ultra-condensed;
          font-weight: 100;
          font-size: 80%;
          opacity: 0.4;
          position: absolute;
          left: -2.5em;
          top: 0;
          width: 2.5em;
          display: block;
          text-align: left;
        }
        .date > .comparison > *::after {
          right: -1.5em;
        }

        .compare_label { 
          font-stretch: ultra-condensed;
          font-weight: 100;
          font-size: 80%;
          opacity: 0.4;
          text-align: right;
        }
        .sys_value {
          color: var(--paper-purple-800);
        }
        .ref_value {
          color: var(--paper-cyan-800);
          /*font-style: oblique;*/
        }
        .comparison > .sys_value::after {
          content: "new";
        }
        .comparison > .ref_value::after {
          content: "old";
        }

        .processing_status[show] {
          opacity: 1;
        }
        column-header {
          position: relative;
          z-index: 1;
        }
        tr[hl] {
          background-color: pink;
        }
        #header-shadow-container {
          position: sticky;
          left: 0px;
          top: 0;
          height: 0;
          /* z-index: 10; FIXME: interferes with period dropdown -- all these z-indexes need some revisiting*/
          width: 100%;
          box-sizing: border-box;
          /*
          width: calc(100vw - 16px);
          opacity: 0.5;
          */
        }
        #header-shadow {
          box-sizing: border-box;
          box-shadow: none;
          transition: box-shadow 200ms linear 0s;
        }

        #header-shadow[scrolled] {
          transition: box-shadow 200ms linear 0s;
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        }

        tfoot td:before {
          content: " " ;
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
          width: 0px;
          height: 48px;
          position: fixed;
          bottom: 0;
          background-color: red;
        }

/*
        tr[total_for='affiliate'] td {
          background-color: var(--paper-blue-100);
        }
        tr[total_for='state'] td {
          background-color: var(--paper-pink-100);
        }
        tr[total_for='global'] td {
          background-color: var(--paper-purple-100);
        }
        */

        tr[total_row] td {
          border-bottom: 3px solid var(--paper-grey-500);
        }
        
        td.total, tr[total_row] td {
          color: white;
          font-weight: 100;
          margin-top: 12px;
          margin-bottom: 12px;
          background-color: var(--reporting-ternary, var(--paper-teal-500));
        }

        td[colname="units"] {
          max-width: 5em;
        }

        /*
        #header-shadow {
          box-shadow: none;
          transition: box-shadow 200ms linear 0s;
        }
        #header-shadow[scrolled]{
          height: 10px;
    position: relative;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
          transition: box-shadow 200ms linear 0s;
    top: -9px;
}
        }*/
`;

export class ReportTable extends LitElement {
  static styles = report_table_style
  constructor() {
    super();
    this.render_limit = 200;
    this.shadow_y = -200;
  }
  static get properties() {
    return {
      report: { type: Object },
      editing_note: { type: Object },
      editing_note_text: { type: String },
      column_edit: { type: String },
      render_limit: { type: Number },
      scrolled: {type: Boolean},
      shadow_y: {type: Number}
    };
  }
  firstUpdated() {
    this.scroller = this.renderRoot.getElementById('scroller');
    //console.log("set scroller ", this.scroller);
    this.dispatchEvent(new CustomEvent('set-scroller', { composed: true, bubbles: true, detail: this.scroller }));
    this.shadow = this.renderRoot.getElementById('header-shadow-container');
    this.shadow_anchor = this.renderRoot.getElementById('shadow-anchor');
    this.shadow_y = this.shadow_anchor ? this.shadow_anchor.getBoundingClientRect().bottom : this.shadow_y;



    let options = {
      root: this.scroller,
      rootMargin: '0px',
      threshold: 0.1
    }

    this.observer = new IntersectionObserver((entries, obs) => {
      if (entries.some(entry => entry.isIntersecting)) this.intersectHandler();
    }, options);

    this.trigger = this.renderRoot.getElementById('watcher');
    this.observer.observe(this.trigger);
  }

  // emulate scrollTop function so this element can be used as app-bar scrollTarget
  get scrollTop() {
    this.scroller = this.scroller ? this.scroller : this.renderRoot.querySelector('.table-scroller');
    return this.scroller ? this.scroller.scrollTop :  null;
  }

  set report(r) {
    this._report = r;
    this.show_progress = false;
    //this.render_limit = r && r.limit ? r.limit : 500;
    this.requestUpdate('report');
  }
  get report() { return this._report }

  addSort(col) {
    if (this.report) {
      this.dispatchEvent(new CustomEvent('add-sort', { detail: col }));
    }
  }
  async intersectHandler() {
    console.log("handle intersect");
    this.show_progress = !this.report || !this.report.data || (this.report && this.report.data && this.render_limit < this.report.data.length);
    this.requestUpdate('show_progress');
    await this.updateComplete;
    console.log("done");
    window.requestIdleCallback(() => this.moreData());
  }
  async moreData() {
    console.log(`intersect: lim: ${this.render_limit}, data: ${this.report && this.report.data && this.report.data.length}`);
    let limit_inc, more_data;
    if (this.report && this.report.data && this.report.data.length >= this.render_limit) {
      limit_inc = true;
      this.render_limit += this.report.limit;
    }
    if (this.report && !this.report.end) {
      this.dispatchEvent(new CustomEvent('more-data', { detail: this }));
      more_data = true;
    }
    if (limit_inc && !more_data) {
      console.log("manually resetting progress ind")
      await this.updateComplete;
      console.log("...now");
      this.show_progress = false;
      this.requestUpdate('show_progress');
    }
  }

  render() {
 
    return html`
    <div class="table-container"
    
            id="scroller"
            @scroll=${e => { this.scrolled = this.scrollTop && this.scrollTop > 0;/*refire a synthetic scroll event to outside shadow dom so we can be used as a scrollTarget*/ this.dispatchEvent(new CustomEvent("scroll", {...e}))}}
    >
            <div id="header-shadow-container"> 
              <div id="header-shadow" ?scrolled=${this.scrolled} style=${this.headerShadowStyle()}> </div>
            </div>
          <div class="table-scroller" >
            <table>
              <thead>
                <tr id="shadow-anchor">
                  ${this.report && this.report.columns ? this.report.columns.map(c => html`
                  ${c.compare ? html`<th></th>` : ''}
                  <th class=${c.t} >
                      <column-header .def=${c} .filter=${this.report.filter} .sort=${this.report.sort} @show-header=${({ detail: { col, hide } }) => this.handleColShow(col, hide)}></column-header>
                    </div>
                  </th>
                  `) : html``}
                </tr>
              </thead>

              <tbody>
              ${this.report && this.report.data && this.report.columns ? repeat(this.report.data.slice(0, this.render_limit), d => d.id, (d, i) => {
      let hl = this.report && this.report.highlight && this.report.highlight(d);
      let total = d.totals_for;

      return total ? 
               html`<tr total_row total_for=${total}> ${this.render_totals_row(d, total)} </tr><tr></tr>`
               : html`
                <tr ?hl=${hl}> ${this.render_row(d)}</tr>
                <tr ?hl=${hl}> ${this.render_row_extra(d)} </tr>
                `
    }) : html``}
              </tbody>
              ${this?.report?.summary ? html`
              <tfoot>
                <tr class="summary_row"> ${this.render_summary_row(this?.report?.summary)} </tr>
              </tfoot>
              ` : ''}
            </table>
            <div id="watcher-holder">
              <div id="watcher"></div>
            </div>
          </div>
      <div class="status_overlay" ?show=${this.show_progress || (this.report && this.report.status !== 'ready')}>
          <progress-circle style="--progress-color: white; --progress-bg: var(--paper-grey-700); --progress-size: 128;" .status=${this.getTableStatus(this.report)} .icon_incomplete=${""} .icon_complete=${""}></progress-circle>
          <span>${this.report && this.report.remaining ? `${this.report.remaining}...` : ''}</span>
      </div>
  </div>
  ${this.renderNoteDialog(this.editing_note)}
    `
  }

  // FIXME: hack for aff notes, but should be generalized
  saveNote(row, note){
    console.log("SAVING", note, this.editing_note_text == this.editing_note?.note);
    this.dispatchEvent(new CustomEvent('save-field', { bubbles: true, composed: true, detail: {field: 'note', value: this.editing_note_text, data: this.editing_note} })); 
  }

  renderNoteDialog(d) {
    
    return html`
      <mwc-dialog ?open=${d} @closed=${e => this.editing_note = null}>
      <div>
      <mwc-textarea
      id="note_entry"
      label=${`${d?.affiliate} notes`}
      .value = ${d?.note ? d.note : ''}
      @input=${e => this.editing_note_text = e.path[0].value}
      rows=10
      >
      
      </mwc-textarea>
      </div>
            <mwc-button
                id="primary-action-button"
                slot="primaryAction"
                @click=${e => this.saveNote(d, this.editing_note_text)}
                ?disabled=${this.editing_note_text == d?.note}
                dialogAction="notesaved">
                Save
            </mwc-button>
          
            <mwc-button
                slot="secondaryAction"
                dialogAction="close">
                Cancel
            </mwc-button>
      </mwc-dialog>
    `

  }
  /*
  <mwc-dialog id="newAgreementDialog" heading="New Master Agreement" open .title="New Master"  
      @closed=${e=>{
          this.dispatchEvent(new CustomEvent('new-agreement-dialog-closed', { bubbles: true, composed: true, detail: null })) 
        }}>
        <div>
        <kale-textfield id="agreementName" label="Agreement Name" field="name" .value="New master Agreement" required 
            @input=${e1 => {
              const path = e1.composedPath();
              const input = path[0];
              input.value.length > 0 ? this.agreement_name_valid = true :  this.agreement_name_valid = false;
            }}
        ></kale-textfield>
        </div>
        <div class="divider"></div>
        
  
      </mwc-dialog>`
      */

  headerShadowStyle() {
    if (!this.shadow_anchor) return '';

    const {top, height} = this.shadow_anchor.getBoundingClientRect();
    const scroll = this.scrollTop;
    return `top: ${0}px; height: ${height}px;`
  }

  handleColShow(col, hide) {
    if (this.col_hide) this.col_hide(col);
    this.col_hide = hide;
  }
  filterActive(c) {
    return true;
  }
  sortActive(c) {
    return false;
  }
  showOpts(e, c) {
    this.column_edit = c === this.column_edit ? null : c;
  }
  getSortIcon(c) {
    let s = this.report && this.report.sort && this.report.sort.find(s => s.col === c);
    if (s && s.reverse) return 'arrow_drop_up';
    if (s) return 'arrow_drop_down';
    return '';
  }
  getTableStatus(report) {
    if (this.show_progress) {
      return 'animate';
    }
    if (report && report.status === 'ready') {
      return 'complete';
    }
    if (report && report.status === 'processing') {
      return 'animate';
    }
    if (report && report.status === 'requested') {
      return 'animate';
    }
    return 'incomplete'
  }
  render_row(d) {
    let last = this.report.columns.length - 1;
    return html`
    ${this.report.columns.map((f, i) => {
      if (f.compare) {
        let sys = d[f.c + '_sys'];
        let match = d[f.c + '_match'];
        return html`
          <td class=${`compare_label ${match ? '' : 'sys_value'}`} compare ?firstcol=${i === 0}  ?lastcol=${i === last}  rowspan=${match ? 2 : 1}>${match ? '' : 'new'}</td>
          <td class=${f.t + (match ? '' : ' sys_value')} compare ?mismatch=${!match} rowspan=${match ? 2 : 1}>${formatters[f.t](sys, f, d)}</td>`
      } else {
        if (f.edit_func) {
          return html`<td editable colname=${f.c} class=${f.t} ?firstcol=${i === 0}  ?lastcol=${i === last} rowspan="2">
            <div class="editable_cell">
            <div class="editable_content" @click=${e => this.openNoteEditDialog(d)}>${formatters[f.t](d[f.c], f, d)}</div>
            </div>
          </td>`
        } else {
          if (d[f.c] === null || d[f.c] === undefined) return html`<td class=${`null ${f.t}`} ?firstcol=${i === 0} rowspan="2">null</td>`
          return html`<td colname=${f.c} class=${f.t} ?firstcol=${i === 0}  ?lastcol=${i === last} rowspan="2">${formatters[f.t](d[f.c], f, d)}</td>`
        }
      }
    })}`
  }
  openNoteEditDialog(d) {
    console.log("EDIT NOTE", d);
    this.editing_note = d;
  }

  render_totals_row(d, t) {
    let values = this.report.columns.filter(c => !c.total);
    let last = values.length - 1;
    return html`
    <td class=${`total ltext`} colspan=${this.report.columns.filter(c => c.total).length} ?firstcol=${true} rowspan="2">TOTAL ${d[t]}</td>
    ${values.map((f, i) => {
        if (d[f.c] === null || d[f.c] === undefined) return html`<td class=${`null ${f.t}`} rowspan="2">null</td>`
        return html`<td colname=${f.c} class=${f.t} ?lastcol=${i === last} rowspan="2">${formatters[f.t](d[f.c], f, d)}</td>`
    })}`
  }


  render_row_extra(d) {
    let last = this.report.columns.length - 1;
    return html`
    ${this.report.columns.map((f, i) => {
      if (f.compare) {
        let ref = d[f.c + '_ref'];
        let match = d[f.c + '_match'];
        return match ? '' : html`
          <td class=${`compare_label ${match ? '' : 'ref_value'}`} compare ?firstcol=${i === 0}  ?lastcol=${i === last}>old</td>
          <td class=${f.t + (match ? '' : ' ref_value')} compare ?mismatch=${!match}>${formatters[f.t](ref, f, d)}</td>`
      }
    })}
    `
  }
  render_summary_row(s) {
    let last = s.length - 1;
    return html`
    ${s.map((c,i) => html`
      <td class=${c.t} ?firstcol=${i === 0}  ?lastcol=${i === last} colspan=${c.colspan ? c.colspan : 1}>${formatters[c.t](c.value)}</td>
    `)}
     `
  }

  close() {
    this.show = false;
    this.dispatchEvent(new CustomEvent('close', { detail: this }));
  }
}

window.customElements.define('report-table', ReportTable);
