import { html, css, nothing } from 'lit';
import { MDuesPage } from '../components/mdues_page.js';
import '@material/mwc-icon';
import '@material/mwc-list';
import '@material/mwc-fab';
import '@material/mwc-button';
import '@material/mwc-dialog';
import '@material/mwc-textfield';
import { KaleForm } from '../shared-components/form.js';
import '../shared-components/card.js';
import { client, formatQueryError } from '../queries/client.js';
import gql from 'graphql-tag';
import { refetch_searches, SearchIncreases,  EditIncrease, EditAgreement, upsert_agreement_mutation, delete_agreement_increases_mutation } from '../queries/queries.js';
import { MDuesResultList, formatAffiliate, formatAffiliateWithState,  MDuesListItem, MduesSearchBox, NewItemDialog, shortdatefmt } from '../components/result_list.js';
const wait = ms => new Promise(resolve => setTimeout(resolve, ms));

export class IncreaseSearch extends MduesSearchBox {
  constructor(){
    super();
    this.search = { master_only: false };
  }
  static properties = {
    ...super.properties,
    master_only: { type: Boolean },
    no_data_only: { type: Boolean }
  }
  renderRowOptionsOld() {
    return html`
      <mwc-formfield label="Masters" class="option">
        <mwc-switch 
        @click=${e => {
          let switch_elem = this.renderRoot.querySelector('mwc-switch');
          let checked = switch_elem?.selected;
          this.search = { ...this.search, master_only: checked }}
        }
        ></mwc-switch>
      </mwc-formfield>
    `
  }
  renderRowOptions() {
    return html`
      <kale-chip
        id="masters-only"
        label="masters only"
        ?selected=${this.master_only}
        @click=${e => {
          this.master_only = !this.master_only;
          this.search = { ...this.search, master_only: this.master_only }}
        }
      ></kale-chip>
      <kale-chip
        id="no-data-only"
        label="no data only"
        ?selected=${this.no_data_only}
        @click=${e => {
          this.no_data_only = !this.no_data_only;
          this.search = { ...this.search, increase_condition: this.no_data_only ? {_not: {increases: {}}} : {increases: {}}}}
        }
      ></kale-chip>
    `
  }
}


//<mwc-textfield @input=${e => this.search = {...this.search, filter: e.target.value}} style="flex: 1 1" outlined label="filter" icon="search"></mwc-textfield>
window.customElements.define('increase-search', IncreaseSearch);


const increase_row_styles = css`
  :host { 
    display: table-row;
  }

  div.cell {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    padding: 12px;
    --mdc-text-field-fill-color: none;
    --mdc-select-fill-color: none;
    --mdc-text-field-idle-line-color: none;
    whitespace: nowrap;
  }
  div.cell.toggle-cell {
    vertical-align: middle;
  }

  div.cell[newrow] {
  }
  div.group {
    display: flex;
    flex: 1;
  }
  div.group > * {
    display: inline-block;
  }

  div.fixer {
    position: relative;
    top: 0;
    left: 0;
  }

  /*
  .cell kale-listbox {
    position: relative;
    top: -24px;
  }*/

  .kale-50 {
    width: 50px !important;
    --kale-textfield-width: 50px;
  }

  .kale-75 {
    width: 75px !important;
    --kale-textfield-width: 75px;
  }

  .kale-100 {
    width: 100px !important;
    --kale-textfield-width: 100px;
  }
  .kale-120 {
    width: 120px !important;
    --kale-textfield-width: 120px;
  }
  .kale-150 {
    width: 150px !important;
    --kale-textfield-width: 150px;
  }
  .kale-175 {
    width: 175px !important;
    --kale-textfield-width: 175px;
  }

`

export class IncreaseRow extends KaleForm {
  static styles = [increase_row_styles];
  static properties = {
    ...super.properties,
    increase: { type: Object },
    //target: { type: Object },
    in_negotiation: { type: Boolean },
    increase_in_negotiation: { type: Boolean },
    is_pct: { type: Boolean },
    time_unit: { type: String },
    is_new: { type: Boolean },
    suppressed: { type: Boolean }
  }
  static form_name = 'IncreaseFormRow'
  static mutation_class = EditIncrease

  dataUpdate(new_data) {
    console.warn("IncreaseRow data update", new_data);
    this.increase = new_data;
  }
  finalUpdate(new_data, method) {
    console.log("finally", method);
    if (method === 'mutate') {
      this.increase = new_data;
    } else if (method === 'delete') {
      this.dispatchEvent(new CustomEvent('deleted', { bubbles: true, composed: true, detail: this.increase }))
    }
    this.dispatchEvent(new CustomEvent('update-list', { bubbles: true, composed: true, detail: this.increase }))
    this.nextMulti();
  }

  get mutation_instance() {
    console.log(`build mutation for ${this.constructor.form_name}: ${this.constructor.mutation_class.name}.`)
    if (!this.constructor.mutation_class) return null;
    if (!this._mutation) this._mutation = new (this.constructor.mutation_class)(
      (increase) => this.dataUpdate(increase),
      { changeMap: null },
      (increase, method) => this.finalUpdate(increase, method),
      (err) => console.error(err) //FIXME: more error checking/alerts
    );
    return this._mutation;
  }

  async saveMulti(parent, rest) {
    console.warn("SAVING MULTI", parent, rest);
    this.multiqueue = [...(this.multiqueue ? this.multiqueue : []), ...rest];
    this.save(parent);
  }

  nextMulti() {
    if (this.multiqueue && this.multiqueue.length > 0) {
      console.warn(this.form_name, "clearing multiqueue", this.multiqueue.length);
      let next = this.multiqueue.pop();
      console.log("NEXT:", next);
      next.form.saveMulti(next.parent, this.multiqueue);
      this.multiqueue = [];
    }
  }
  async save_impl(data) {
    const { year, agreement_id, row_src, state, council, local, subunit, employer, master, members, type, value, base, eff_dt, in_negot, contact, notes, agr_eff_dt, agr_exp_dt } = this.increase;
    data = { year, 
      agreement_id,
      state,
      council,
      local,
      subunit,
      employer,
      master,
      members,
      type,
      value,
      base,
      eff_dt,
      in_negot,
      contact,
      notes,
      agr_eff_dt,
      agr_exp_dt,
      ...data }; 
    const mut = this.mutation_instance;
    console.log("INCREASE ROW SAVE_IMPL?", JSON.stringify(data, null, 2), JSON.stringify(this.increase, null, 2));
    //console.log("TARGET", this.target);
    console.log("INCREASE", this.increase);
    if (!mut || !this.increase) return;
    data.id = this.increase.id;
    await mut.save(data, { ...this.increase });
   // mut.save(inc, { ...this.increase, agreement_id: this.target.id});
    this.saveComplete(Object.keys(data));
    this.dispatchEvent(new CustomEvent('snackbar', { bubbles: true, composed: true, detail: { text: `saved` } })); // TODO: undo
    await this.recompute();
  }
  async recompute(deleted) {
    this.dispatchEvent(new CustomEvent('prelim-update', { bubbles: true, composed: true, detail: { deleted, increase: this.increase }}))
    let recompute = window.async_recompute_sums();
    await recompute;
    this.dispatchEvent(new CustomEvent('stale-sums', { bubbles: true, composed: true, detail: this.increase }))
  }
  async delete() {
    const mut = this.mutation_instance;
    if (!mut || !this.increase) return;
    await mut.delete(this.increase);
    this.dispatchEvent(new CustomEvent('snackbar', { bubbles: true, composed: true, detail: { text: `deleted` } })); // TODO: undo
    await this.recompute(true);
  }

  setUnit(increase_type) {
    if (!increase_type) return;
    console.warn("SET UNIT", increase_type);

    this.is_pct = increase_type === 'pct';
    switch (increase_type) {
      case 'hourly':
        this.time_unit = 'hour';
        break;
      case 'yearly':
        this.time_unit = 'year';
        break;
      default:
        this.time_unit = undefined;
        break;
    }
  }

  set increase(i) {
    this._inc = i;
    if (this.is_pct === undefined) this.setUnit(i.increase_type);
    if (this.increase_in_negotiation === undefined) this.increase_in_negotiation = i.in_negotiation;
    this.requestUpdate('increase');
  }

  get increase() {
    return this._inc;
  }

  set is_new(v) {
    this._is_new = v;
    if(v) this.setUnit('pct');
    this.requestUpdate('is_new');
  }
  get is_new() {
    return this._is_new;
  }

  static mutation_class = EditIncrease
  static form_name = "IncreaseRow"
  static type_items = [
    { name: 'Percent', code: 'pct' },
    { name: 'Hourly', code: 'hourly' },
    { name: 'Yearly', code: 'yearly' },
  ]

  getCurVal(field) {
    if (this.dirty_map.has(field)) {
      return this.dirty_map.get(field).value
    }
    return this.increase[field];
  }

  /*
  firstUpdated() {
    super.firstUpdated();
    if (!this.increase) this.setUnit('pct');
  }*/

  render() {
  
    const {is_new} =  this;
    const { id, agreement_id, row_src, state, council, local, subunit, employer, master, members, type, value, base, eff_dt, in_negot, contact, notes, updated, agr_eff_dt, agr_exp_dt, sums } = this.increase;
    console.log("INC ROW", this.increase, this.is_pct, this.in_negotiation);
    const in_negotiation = this.in_negotiation ?? in_negot;
    const is_pct = this.is_pct ?? type === 'pct';


    //EMPLOYER	MEMBERS	AGR. EFF.	AGR. EXP.	IN NEGOT.	EFFECTIVE	INCREASE	MEMBERS × INCREASE	COMMENT
    return html`
<div class="cell" ?newrow=${is_new}>
  <kale-textfield id="employer"
    ?outlined=${is_new}
    class="kale-150"
    placeholder="Employer"
    .value=${employer}
    field="employer"
  ></kale-textfield>
</div>
<div class="cell" ?newrow=${is_new}>
  <kale-textfield id="members"
    class="kale-120"
    ?outlined=${is_new}
    inputtype="number"
    label="Members"
    placeholder="Members"
    .value=${members}
    field="members">
  </kale-textfield>
</div>
<div class="cell" ?newrow=${is_new}>
  <kale-date id="agr_eff_dt"
    class="kale-100"
    ?outlined=${is_new}
    label="Agr. Eff."
    placeholder="Agr. Eff."
    field="agr_eff_dt"
    .value=${agr_eff_dt}></kale-date>
</div>
<div class="cell" ?newrow=${is_new}>
  <kale-date id="agr_exp_dt"
    class="kale-100"
    ?outlined=${is_new}
    label="Agr. Exp."
    placeholder="Agr. Exp"
    field="agr_exp_dt"
    .value=${agr_eff_dt}></kale-date>
</div>
<div class="cell toggle-cell" ?newrow=${is_new}>
  <kale-toggle id="in_negot"
    .value=${in_negot}
    field="in_negot"
    @component-dirty=${({ detail: { value } }) => { console.log("inc in neg => ", value); this.in_negotiation = value }}>
  </kale-toggle>
</div>
<div class="cell" ?newrow=${is_new} style="width: 50px;">
  <kale-listbox id="type"
    class="kale-120"
    ?outlined=${is_new}
    label="Type"
    ?disabled=${in_negotiation}
    placeholder="Type"
    .value=${type ? type : 'pct'}
    field="type"
    .items=${this.constructor.type_items}
    @component-dirty=${({ detail: { value } }) => this.setUnit(this?.renderRoot?.getElementById('type')?.value)}
  > </kale-listbox>
</div>
<div class="cell" ?newrow=${is_new}>
  <kale-textfield id="value"
    class="kale-175"
    ?outlined=${is_new}
    inputtype="number"
    placeholder="amt"
    ?disabled=${in_negotiation}
    suffix=${this.is_pct || this.time_unit === undefined ? '%' : `$/${this.time_unit[0].toUpperCase()}`} 
    .value=${value}
    field="value"
  ></kale-textfield>
</div>
<div class="cell" ?newrow=${is_new}>
  <kale-textfield id="base"
    class="kale-175"
    ?disabled=${is_pct || in_negotiation}
    ?outlined=${is_new}
    inputtype="number"
    placeholder=${is_pct ? 'N/A' : 'base'}
    suffix=${this.is_pct || this.time_unit === undefined ? '' : `$/${this.time_unit[0].toUpperCase()}`}
    .value=${base ? base : null}
    field="base"></kale-textfield>
</div>

<div class="cell" ?newrow=${is_new}>
  <kale-date id="eff_dt"
    class="kale-100"
    ?outlined=${is_new}
    label="Effective"
    placeholder="Base"
    field="eff_dt"
    .value=${eff_dt}></kale-date>
</div>
<div class="cell" ?newrow=${is_new}>
  <kale-textarea id="notes"
    class="kale-150"
    ?outlined=${is_new}
    placeholder="notes"
    style="max-width: 50px;"
    .value=${notes ? notes : null}
    field="notes"></kale-textarea>
</div>
<div class="cell">

  ${id ? html`<mwc-icon-button @click=${e => { this.delete(); }} icon="delete"></mwc-icon-button>` : '' }
</div>
    `
  }
}
window.customElements.define('increase-row', IncreaseRow);


const increase_item_styles = css`
  :host {
    --item-primary-color: var(--virtual-color);
  }

  :host([increase_type=local]) {
    --item-primary-color: var(--local-color) !important;
  }
  :host([increase_type=master]) {
    --item-primary-color: var(--master-color) !important;
  }



  .alias-chip {
    --chip-background: var(--alias-color);
    --chip-color: white;
  }
  .item-chip.local-target {
    --chip-background: var(--local-color);
    --chip-color: white;
  }
  .item-chip.master-target {
    --chip-background: var(--master-color);
    --chip-color: white;
  }


  .increase_table {
    box-sizing: border-box;
    width: 100%;
/*     box-shadow: var(--shadow-elevation-3dp_-_box-shadow); */
    margin-top: 18px;
    padding: 8px;

  }

  table {
    width: 100%;
  }
  
  td, th {
    text-align: center;
    padding: 8px;
  }

	td.left, th.left {
    text-align: left;

	}

  th {
    background: var(--paper-grey-700);
    color: white;
    text-transform: uppercase;
    font-size: 90%;
    font-weight: 100;
  }
  tr:nth-child(even) {background: #CCC}
tr:nth-child(odd) {background: #FFF}

  td.button {
    background: white;
  }

  .header-actions {
    float: right;
  }
  .footer-info {
    margin-top: 18px;
  }

  .footnote { 
    font-size: 90%;
    font-weight: 100;
    opacity: 0.9;
    margin: 1em;
  }

  div.control_row {
    display: flex;
    flex-direction: row;
    align-contents: center;
  }
  div.control_row > * {
    margin-right: 12px;
  }
  div.control_row > mwc-formfield {
    position: relative;
    top: 12px;
  }
  div.control_row  kale-toggle {
    position: relative;
    bottom: 12px;
  }

  div.agreementid {
    padding-left: 30px;
    font-size: 60%;
    font-weight: 100;
    display: inline-block;
  }

  table.fixed { 
/*     table-layout:fixed;  */
width: 40vw;
  }
  
  .fixed td {
    padding: 5px;
    background-color: var(--item-primary-color);
    //background-color: blue ;
    color: white;
    font-size: 15px;
    text-align: center;
		min-width: 20px;
		white-space: nowrap;
  }
  
  .fixed td.master {
    background-color: var(--master-color) ;
    color: white;
    font-size: 15px;
    text-align: center;
  }  
	.info_labels td {
	font-size: 10px;
	}

  .negotiations_indicator {
    border-radius: 10px;
    background-color: var(--paper-orange-900);
    color: white;
    padding: 4px 8px;
    position: relative;
    top: -6px;
  }

  .info_info.header_date {
    font-size: 12px;
    display: block;
    max-width: 55px;
    overflow: hidden;
    text-overflow: ellipsis;
  }



  .master_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

	span.name {
		font-weight: 900;
	}
	span.aff {
		font-weight: 100;
		display: inline-block;
		border: 1px solid var(--paper-grey-300);
		border-radius: 5px;
		padding: 5px 10px;
		margin-right: 20px;
		white-space: nowrap;
    width: 5em;
	}
	span.state {
		font-weight: 900;
		font-size: 115%;
		display: inline-block;
		margin-right: 6px;
		text-align: left;
	}
	span.local {
		font-weight: 900;
		font-size: 115%;
		display: inline-block;
		margin-right: 6px;
		text-align: center;
	}
	span.masterM {
		font-weight: 900;
		font-size: 125%;
		display: inline-block;
		margin-right: 6px;
		text-align: center;
	}
	span.council {
		font-size: 96%;
		display: inline-block;
		text-align: right;
	}
  .master_header > mwc-icon {
    color: var(--paper-red-500);
    margin-left: 20px;
  }
`;


export class OrganizationItem extends MDuesListItem {
  static styles = [...super.styles, increase_item_styles];
  static properties = { item: { type: Object }, increase_type: { type: String }, in_negotiation: {type: Boolean}, from_dialog: {type: Boolean}}

  static mutation_class = EditAgreement

  setAgreementInfo(i) {
   // console.log("SET AGREEMENT INFO", i);
    const {is_master, in_negot} = i
    this.increase_type = is_master ? 'master' : 'local';
    if (this.increase_type) {
      this.setAttribute('increase_type', this.increase_type);
    }
    this.in_negotiation = in_negot;
    this.some_negotiation = i?.increases?.some(inc => inc.in_negot);
  }

  set item(i) {
    i.increases = i.increases.filter(inc => inc.id && !this.deletedRows?.includes(inc.id));
    this._item = i;
		this.setAgreementInfo(i);
    this.requestUpdate('item');
  }
  get item() { return this._item }

  async save_impl(data) {
    // const mut = this.mutation_instance;
    // console.log("!!!!!!ORG ITEM SAVE_IMPL", mut, data, this.item);
    // if (!mut || !this.item) return;
    // //mut.save(data, { ...this.item});
    this.saveComplete(Object.keys(data));
  }

  chipClass(item) {
    return `item-chip ${item.master ? 'master-target' : 'local-target'}`
  }

  dataUpdate(new_data) {
    this.item = new_data;
  }
  finalUpdate(new_data) {
    this.item = new_data;
    this.requestUpdate("item");
    //this.editing = false;
  }

  handlePrelimUpdate({ deleted, increase }) {
    if (deleted) {
      this.handleDelete(increase);
    } else {
      let idx = this.item.increases.findIndex(i => i.id === increase.id);
      if (idx >= 0) {
        this.item.increases[idx] = increase;
      } else {
        this.item.increases.push(increase);
      }
      this.finalUpdate({ ...this.item, increases: this.item.increases});
    }
  }

  handleDelete(row) {
    this.finalUpdate({ ...this.item, increases: this.item.increases.filter(i => i.id !== row.id) });
    this.deletedRows = [...(this.deletedRows ? this.deletedRows : []), row.id];
  }


  renderEditableIncreases(increases) {
          // ${this.increase_type === 'master' ? html`<th>in negot.</th>` : ''}
          // ${this.in_negotiation ? '' : html`
          //`}
    console.log("RENDER ORG INCS", increases, this.item);
    return html`
    <table>
      <thead>
        <tr>
          <th>emp.</th>
          <th>mbrs</th>
          <th>agr. eff.</th>
          <th>agr. exp.</th>
          <th>in negot.</th>
          <th>type</th>
          <th>inc.</th>
          <th>base</th>
          <th>inc. eff.</th>
          <th>notes</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        ${
        [...increases
        ,
        {
          year: this.item.year,
          agreement_id: this.item.id,
          in_negot: false,
          master: this.item.master,
          state: this.item.state,
          council: this.item.council,
          local: this.item.local,
          base: null,
          type: 'pct',
        }
        ].map(i => html`
        <increase-row
          .is_new=${!i.id}
          @prelim-update=${({ detail }) => this.handlePrelimUpdate(detail)}
          @deleted=${({ detail }) => this.handleDelete(detail)}
          .is_master=${this.increase_type === 'master'}
          .increase=${i}
          .in_negotiation=${this.in_negotiation}
        ></increase-row>`)}
      </tbody>
    </table>
    `;
          //.target=${i}
  }

  formDirty(form, name) {
    console.log("FORM DIRTY", form, name, this.dirty_map);

  }
  
  renderEditForm(item) {
    //console.log("RENDER ORG EDIT FORM", item);
    //const { in_negotiation, effective_date, expires_date, stat_cap, stat_override, members, total_members, adjusted_members, total_adjusted_members, adjusted_members_in_negotiation, total_adjusted_members_in_negotiation, weighted_increase, total_weighted_increase, increases } = item;
    const { year, is_master, state, council, local, master, locals, increases, sums: {total_members, total_stat, total_weighted, members_in_negotiation, total_adjusted_members } } = item;
      /*
        <div class="control_row">
        <mwc-formfield label="In Negotiations">
          <kale-toggle id="in_negotiation" .value=${item.in_negotiation} field="in_negotiation" @component-dirty=${({ detail: { value } }) => { console.log("in neg => ", value); this.in_negotiation = value }}></kale-toggle>
        </mwc-formfield>
        <kale-date label="Effective" field="effective_date" .value=${item.effective_date}></kale-date>
        <kale-date label="Expires" field="expires_date" .value=${item.expires_date}></kale-date>
        <kale-textfield type="number" label="Manual Stat Override" field="stat_override" .value_transform=${v => {console.log("transforming", v, " => ", v?.trim() === '' ? null : v); return v?.trim() === '' ? null : v;}} .value=${item.stat_override}></kale-textfield>
      </div>
			<div class="target-info">
				<div class="info_item">
					<span class="info_label">stat</span>
					<span class="info_info">${stat_cap !== null ? stat_cap.toLocaleString() : html`&mdash;`}</span>
				</div>
				<div class="info_item">
					<span class="info_label">members${total_members !== members ? ` (group)` : ''}</span>
					<span class="info_info">${members !== null && members !== undefined ? members.toLocaleString() : html`&mdash;`}${total_members !== members ? html` (${total_members !== null ? total_members.toLocaleString() : html`&mdash;`})` : ''}</span>
				</div>
				<div class="info_item">
					<span class="info_label">adjusted${total_adjusted_members !== adjusted_members ? ` (group)` : ''}</span>
					<span class="info_info">${adjusted_members !== null && adjusted_members !== undefined ? adjusted_members.toLocaleString() : html`&mdash;`}${stat_override ? html`&ast;` : ''}${total_adjusted_members !== adjusted_members ? html` (${total_adjusted_members !== null && total_adjusted_members !== undefined ? total_adjusted_members.toLocaleString() : html`&mdash;`})` : ''}</span>
				</div>
				<div class="info_item">
					<span class="info_label">in negotiation${total_adjusted_members_in_negotiation !== adjusted_members_in_negotiation ? ` (group)` : ''}</span>
					<span class="info_info">${adjusted_members_in_negotiation !== null && adjusted_members_in_negotiation !== undefined ? adjusted_members_in_negotiation.toLocaleString() : html`&mdash;`}${total_adjusted_members_in_negotiation !== adjusted_members_in_negotiation ? html` (${total_adjusted_members_in_negotiation !== null && total_adjusted_members_in_negotiation !== undefined ? total_adjusted_members_in_negotiation.toLocaleString() : html`&mdash;`})` : ''}</span></div>
          </div>
          */
    return html`
      <div class="divider"></div>

      <div class="increase_table">
        ${this.renderEditableIncreases(increases)}
      </div>

    `;
      //${stat_override ? html`<div>&ast; STAT set manually on this local/agreement</div>` : ''}
  }
  get item_date() {
    return this?.item?.updated;
  }

  //renderHeaderName({ agreement: {master, state, council, local}, locals } = {}) { 
  renderHeaderName(item) { 
     const  {master, state, council, local, locals } = item ?? {};
		const name = master ?? locals.length === 1 ? locals[0].local.name : "UNKNOWN";
    if (name == "UNKNOWN") {
      console.log(item);
      console.log(master, state, council, local);
    }
    const aff = council ? `C${council}` : `L${local}`;
		return html`
		<div class="master_header">
			<span class="aff">
			<span class="state">${state}</span>
			<span class="council">${aff}</span>
			</span>
			${local ? html`

			<span class="aff">
			<span class="local">L${local}</span>
			</span>
			` : html`
			<span class="aff">
			<span class="masterM">M</span>
			</span>
			`}
			<span class="name">${name}</name>
			</div>`
	}
  
  renderHeaderDetail(agreement) {
    const {
			locals,
			stat_override,
			in_negot, // FIXME: these three don't really exist on agreement now
			eff_dt,
			exp_dt,
      increases,
			sums: {
				total_stat,
				total_members,
				total_adjusted_members,
				members_in_negotiation,
				total_weighted
			}
		} = agreement;  
    let actual_stat_cap = stat_override ? stat_override : total_stat;
    let last_update = increases.map(i => new Date(i.updated)).reduce((t, c) => c > t ? c : t, 0);
    if (!locals) return html`<div class="local">no locals</div>`;
    
    let unique = Array.from((new Set(locals.map(l=> `${formatAffiliateWithState(l)}`)).values()));
 
    let rendered = unique.slice(0, 5);
    let excess = unique.slice(5);

    // This table layout is crunchy. We'll need to refactor at some point
    
    return html` 
<table class="fixed">
	<tr class="info_labels">
	<td></td>
		<td>Mbrs</td>
		<td>STAT</td>
	<td>Adj</td>
		<td>In Neg</td>
		<td>Mbrs x Inc</td>
	<td>Last Update</td>
	</tr>

	<tr>
		<td>${this.item.in_negot ? html`<div class="negotiations_indicator">IN NEG.</div>` : ''}</td>
		<td>
			<div>
				<span class="info_info">${total_members?.toLocaleString()}</span>
			</div>
		</td>
		<td>
			<div>
				<span class="info_info">${actual_stat_cap !== null && actual_stat_cap !== undefined ? actual_stat_cap.toLocaleString() : html`&mdash;`}${stat_override ? html`&ast;` : ''}</span>
			</div>
		</td>
		<td>
			<div>
				<span class="info_info">${total_adjusted_members !== null && total_adjusted_members !== undefined ? total_adjusted_members.toLocaleString() : html`&mdash;`}${stat_override ? html`&ast;` : ''}</span>
			</div>
		</td>
		<td>
			<div>
				<span class="info_info">${members_in_negotiation !== null && members_in_negotiation !== undefined ? members_in_negotiation.toLocaleString() : html`&mdash;`}${members_in_negotiation !== members_in_negotiation ? html`(${members_in_negotiation.toLocaleString()})` : ''}</span>
			</div>
		</td>
		<td>
			<div>
				<span class="info_info">
					${total_weighted !== null && total_weighted !== undefined ?
					total_weighted.toLocaleString()
					: (in_negot ? html`&empty;` : html`&mdash;`)}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span class="info_info header_date" title=${shortdatefmt(last_update)}>${shortdatefmt(last_update)}</span>
			</div>
		</td>
	</tr>
</table>
`;
	}

  itemChipIcon(agreement) {
    return this.from_dialog ? '' : super.itemChipIcon(agreement);
  }
    
  renderItemChipTip(agreement) {
    let units = agreement?.units;
    let states = Array.from(new Set(units.map(u => u?.unit?.state)).keys());
    if (units) {
      if (units.length === 1) {
        return `${agreement.name} - ${formatAffiliateWithState(units?.[0]?.unit)}${units?.[0]?.unit?.subunit && String(units?.[0]?.unit?.subunit).trim() !== '' ? ` / subunit ${units[0]?.unit?.subunit}` : ''}`
      } else {
        return states.map(s => `${s}: ${units.filter(u => u?.unit?.state === s).length} units`).join(' / ');
      }
      
    } else {
      return agreement.name;
    }
  }

  renderItemChipDesc(agreement) {
    let units = agreement?.units;
    let states = Array.from(new Set(units.map(u => u?.unit?.state)).keys());
    if (units) {
      if (units.length === 1) {
        return `${agreement.name} - ${formatAffiliateWithState(units?.[0]?.unit)}${units?.[0]?.unit?.subunit && String(units[0]?.unit?.subunit).trim() !== '' ? ` / subunit ${units[0]?.unit?.subunit}` : ''}`
      } else {
        return `${agreement?.name}`;
        //return `${agreement?.name} (${states.join('/')})`;
      }
      
    } else {
      return agreement.name;
    }
  }



  renderHeaderId(agreement) {
    return html`<div class="agreementid">${agreement.id}</div>`;
  }
  //renderItemBody({units=[]}={}) { return html`<div class="aliases">${this.renderUnits(units.map(u => u.unit))}</div>` }
	//
	get body_card () { return false; }
  renderItemBody(agreement) {
		
    return html`
          <div class="increase_table">
            ${this.renderIncreases(agreement)}
          </div>
          ${agreement.stat_override ? html`<div>&ast; STAT set manually on this unit/agreement</div>` : ''}
     `
  }

  renderUnitList(target) {
    if (!target) return '';
    const { units } = target;
    return html`${units.map(({ unit: u }) => html`<div class="unit">${u.name}</div>`)}`;
  }
  renderUnitAff(target) {
    if (!target) return '';
    const { units: [{ unit }] } = target;
    console.log("render unit", unit);
    const aff = [
      ['C', 'council'],
      ['L', 'local'],
      ['U', 'sublocal']
    ].filter(([_, p]) => unit[p]).map(([abb, field]) => `${abb}${unit[field]}`).join(' / ');
    return html`<div class="unit">${aff}</div>`;
  }

  
  renderIncreases(agreement) {
    const {
			increases,
			//increase_sums,
			sums: {
				total_stat,
				total_members,
				total_adjusted_members,
				members_in_negotiation,
				total_weighted
			}
		} = agreement;  
    //console.log("RENDER INCREASES", agreement, increases);

		const combined = increases.map(i => ({...i, ...(i.sums)}));
    //console.log("RENDER INC", combined)
			//${this.increase_type === 'master'&& this.some_negotiation ? html`<th>in negotiation</th>` : ''}
    return html`
<table>
	<thead>
		<tr>
			<th class="left">employer</th>
			<th>members</th>
			<th>adjusted</th>
			<th>agr. eff.</th>
			<th>agr. exp.</th>
      <th>in negot.</th>
			${! this?.item?.in_negotiation ? html`
			<th>effective</th>
			<th>increase</th>
			<th>members &times; increase</th>
			` : '' }
			<th>notes</th>
		</tr>
	</thead>
	<tbody>
		${combined.map((
		{ 
		employer,
		members,
		in_negot,
		eff_dt,
    agr_eff_dt,
    agr_exp_dt,
		increase_pct = 0,
		adjusted_members,
		adjusted_members_in_negot,
		weighted_increase,
		notes
		}) => html`
		<tr>
			<td class="left">${employer}</td>
			<td>${members?.toLocaleString()}</td>
			<td>${adjusted_members?.toLocaleString()}</td>
			<td>${agr_eff_dt !== null ? (new Date(agr_eff_dt)).toLocaleDateString() : html`&mdash;`}</td>
			<td>${agr_exp_dt !== null ? (new Date(agr_exp_dt)).toLocaleDateString() : html`&mdash;`}</td>
			<td>${adjusted_members_in_negot?.toLocaleString()}</td>
			<td>${eff_dt !== null ? (new Date(eff_dt)).toLocaleDateString() : html`&mdash;`}</td>
			<td>${increase_pct !== null ? increase_pct?.toLocaleString?.() : 0}%</td>
			<td>${weighted_increase !== null ? weighted_increase?.toLocaleString?.() : 0}</td>
			<td>${notes}</td>
		</tr>
		`)}
	</tbody>
</table>
    `;
			//${this.increase_type === 'master' && this.some_negotiation ? html`<td>${in_negot ? adjusted_members?.toLocaleString() : '0'}</td>` : ''}
    // <td class="button"><mwc-icon-button icon="delete"></mwc-icon-button><mwc-icon-button icon="edit"></mwc-icon-button></td>
  }

  extra_edit_actions = [
    // {
    //   name: 'delete',
    //   icon: 'delete_sweep',
    //   action: (e, item) => this.dispatchEvent(new CustomEvent('demote', { bubbles: true, composed: true, detail: item }))
    // }
  ]
}

window.customElements.define('org-item', OrganizationItem);



const increase_list_styles = css`
.pending_targets { 
	box-sizing: border-box;
	padding: 12px;
}

.card-header-table {
	display: table;
	width: 86%;

	border:0px;
	margin: 0;
	margin-left: auto;
	margin-right: auto;
}
.chip-list {
	flex: none;
}

`;

export class OrganizationList extends MDuesResultList {
  static styles = [...super.styles, increase_list_styles];
  static properties = { ...super.properties, promoted: { type: Object } };
  static search_class = null;

  constructor() {
    super();
    this.search = { master_only: false };
    this.active_sorts = [{ name: 'updated', direction: 'desc' }, { name: { agreement: { name: "asc" } } }];
    this._debounce_time = 1500;
    this._timeout_time = 10000;
  }
  
  static valid_sorts = [
        {field: 'name', display: 'Name', width: 88},
        {field: 'updated', display: 'Date', width: 12}
  ]
      
  get search_class() {
    return this.q ? this.q : this.constructor.search_class;
  }
  
  render() {
    if (this.presentation === 'selection') {
      return html`
      <div class="chip-list">${this.results.map(result => this.renderSelectionItem(result))}</div>
      `
    }


		//console.log("render results", this.results);

    //     ${cached && cached.length > 0 ? cached.map(result => this.renderSelectionItem(result, true)) : nothing}
    //     ${core && core.length > 0 ? core.map(result => this.renderItem(result)) : nothing}
    // 
    return html`
      ${this.only_chips ? nothing : this.renderSortHeaders()} 
      <div class=${`column ${!this.only_chips ? 'centered' : ''}`} ?in-progress=${this.in_progress}>
        ${this.new_item ? this.renderItem(this.new_item, true) : nothing}
				${this.results.map(result => this.renderItem(result))}
      </div> 
      ${this.in_progress ? html`
      <mwc-circular-progress class=${ this.only_chips ? 'chips-progress' : 'page-progress' } indeterminate></mwc-circular-progress>
    ` : ''}
      `;
  }
  
        //${repeat(this.results.filter(r => r.__typename ==='core_agreement_info'), r => r.id, result => this.renderItem(result))}
  formatSort() {
        let x = Object.fromEntries(this.active_sorts.map(({name, direction}) => {
            switch(name) {
                case 'name':
                    return ["agreement", {name: direction}];
                default:
                    return [name, direction]
            }
            }));
        
        //console.log("new sort order:", JSON.stringify(x));
        return x;
        
  }
  
  updateTargetData(target) {
    console.log("UPDATING", target);
    this.results = [target, ...this.results.filter(r => r.id !== target.id)]//.sort((a,b) => new Date(a) - new Date(b)) ;
    console.log("new results", this.results);
    this.requestUpdate('results');
  }

 
  demoteItem(target) {
    console.log("demoting", target);

    const delete_inc = gql`
        mutation clear_increases($agr: uuid!) {
          increases:delete_core_increase (
            where: {agreement: {uid: {_eq: $agr}}}
          ) {
            returning {
              id
              agreement_id
            }
          }
          
        }
    `;
    const delete_agr = gql`
        mutation demote_agreement($agr: uuid!) {
          agreement:delete_core_agreement_info (
            where: {uid: {_eq: $agr}}
          ) {
            returning {
              agreement_id
              uid
            }
          }
          
        }
    `;

    client.mutate({
      mutation: delete_inc,
      variables: {agr: target.uid},
     }).then(data => {
       console.log("deleted increases:", data);
       client.mutate({
         mutation: delete_agr,
         variables: { agr: target.uid },
         refetchQueries: refetch_searches
       }).then(data => {
         console.log("deleted agreement info:", data);
         this.dispatchEvent(new CustomEvent('snackbar', { bubbles: true, composed: true, detail: { kind: 'success', text: `${target.agreement.name} data cleared` } })); // TODO: undo
       }).catch(error => {
         this.dispatchEvent(new CustomEvent('snackbar', { bubbles: true, composed: true, detail: { kind: 'error', text: `delete failed` } })); // TODO: undo
         formatQueryError(error);
       });
     }).catch(error => {
       this.dispatchEvent(new CustomEvent('snackbar', { bubbles: true, composed: true, detail: { kind: 'error', text: `delete failed` } })); // TODO: undo
       formatQueryError(error);
     });
    
  }

  promoteMaster(target) {
    const edit_target = new EditAgreement(
      a => { console.log("MASTER PROMOTE", a) }, // update
      { changeMap: null }, // initial
      a => {
        console.log("FINAL MASTER PROMOTE", a);
        this.updateTargetData({ ...target, promoted: true });
        this.refresh();
      },
      (e, msgs) => { console.error(e) } //errors
    );

    edit_target.save({ agreement_id: target.id}, target);
  }

  promoteUnit(item) {
    const vars = {
      agreement_id: item.agreement_id,
      period_id: item.period_id,
    };
    console.log("PROMOTE UNIT", vars)
    console.log(JSON.stringify(vars));

    client.mutate({
      mutation: upsert_agreement_mutation,
      variables: vars
    }).then(data => {
      console.log("insert succeeded?", data.data.agreement.returning);
      data.data.agreement.returning.forEach(target => {
        this.updateTargetData(target);
        this.refresh();
      })

    })
      .catch(error => { console.log(error); formatQueryError(error) });
  }

  
  promoteTarget(target) {
    //this.promoted = target.id;
    //this.promoteMaster(target);

    this.results = this.results.filter(item => item !== target);
    
    //console.log("this.results ================ ", this.results);
    //this.selectionresults = [...this.selectionresults, target];
    
   this.requestUpdate();
  }

  promoteTargets(selection) {     
      selection.forEach(target => {this.promoted = target.id;  this.promoteMaster(target) } );
  }

  
  renderSelectionItem(item, from_dialog=false) {
    return html`<org-item @update-list=${() => this.refresh()} ?from_dialog=${from_dialog} selectable .item=${item} @item-selection=${e => this.promoteTarget(e.detail)}></org-item>`;
  }

  renderItem(item) {
    return html`<org-item
          @stale-sums=${(e) => {
          console.warn("ORG STALE SUMS", e.detail)
          this.force_refresh();
          }}

      @update-list=${() => this.refresh()} @demote=${e => this.demoteItem(e.detail)} @close=${() => this.promoted = null} .editing=${null} .item=${item}></org-item>`;
  }

  handleSearchResults(seq, res) {
    if (super.handleSearchResults(seq, res)) {
      //console.log(`${this.only_chips? 'promotable' : 'full'} results`, res);
      const t = this.results.find(t => t.period && t.period.totals);
      if (t) {
        this.dispatchEvent(new CustomEvent('status', { bubbles: true, composed: true, detail: t.period.totals}));
      } else {
        this.dispatchEvent(new CustomEvent('status', { bubbles: true, composed: true, detail: null }));
      }      
    }
  }
}

window.customElements.define('org-list', OrganizationList);

const increases_page_styles = css`
      :host { 
        background-color: white;
        --top-bar-color: var(--increase-color);
      }
  
      .column {
        background-color: var(--page-bg, var(--paper-grey-200));
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column; 
        width: 100%;
        min-height: 100%;
        box-sizing: border-box;
        padding: 0px;
      }
      .column > * {
      }

      .column.centered {
        align-items: center;
      }
      
      .row {
        flex: 1 1;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .centered_row {
        justify-content: center;
      }


      .card {
        box-sizing: border-box;
        background-color: white;
        border: 1px solid var(--paper-grey-400);
        padding: 12px;
        padding-bottom: 16px;
        border-radius: 8px;
        transition: var(--shadow-transition);
        cursor: text;
      }

        .card:hover {
          box-shadow: var(--shadow-elevation-8dp_-_box-shadow);
        }
        #search_box {
         font-size: 22px;
        }

        #search_box > #search_text_editable {
          display: inline-block;
          margin-left: 8px;
          min-width: 10px;
        }

        #search_text_editable,#search_text_editable:active,#search_text_editable:focus   {
            border:none;
            outline:none;
         }
         
         #search_text_editable[empty]::before {
          content: "Search";
          color: var(--paper-grey-500);
         }

        #search_text_editable:active::before,#search_text_editable:focus::before {
          content: "";
        }

        #results {
          padding: 0;
        }
        
        .result {
          padding: 4px;
          color: var(--paper-grey-800);
               display: flex;
                 flex-direction: row;
          justify-content: space-between;
          align-content: center;
          align-items: center;

        }
        .result > div {
          width: fit-content;
          margin: 8px;
        }

        .graphic {
          width: 64px;
        }
        .info {
          flex: 3;
          
        }
        .name {
          
        }
        .subinfo {
          font-size: 80%;
          color: var(--paper-grey-600);
          
        }
        .actions {
          text-align: right;
          
        }
      
        .top-app-bar-adjust {
          margin-top: 64px;
        }
        mwc-fab {
          position: fixed;
          right: 48px;
          bottom: 48px;
        }

        .drawer {

        }

        #units {
          height: 100%;
          max-width: max( 50vw, 800px);
          box-shadow: var(--shadow-elevation-3dp_-_box-shadow);
        }
        #detail {
          height: 100%;
          flex: 1 1;
          width: 100%;
        }

`;


/*



        <!-- <org-list .only_chips=${true} .q=${SearchAgreements} .mdues_period=${this.mdp ? this.mdp.id : null} .search=${{...this.search, promoted: false}} -->
        <!-- @detail=${({ detail }) => this.detail = detail}  @status=${({ detail }) => this.status = detail}></org-list> -->
 
 * */

class IncreasesPage extends MDuesPage {
  static styles = [super.styles, increases_page_styles]
  static icon = "attach_money"
  static default_title = "Increases"
  static properties = { ...super.properties, detail: {type: Object }, new_dialog_open: { type: Boolean }, new_dialog_error: { type: Boolean }}
	constructor() {
		super();
		this._mdues_page_instance = Math.random();
	}

  renderPage() {
		//console.warn("RENDERING INCREASE PAGE", this._mdues_page_instance, this.period_year ?? null, this.search, this.status);
		//console.log("INCREASES: ", `period_year=${JSON.stringify(this.period_year)}, search=${JSON.stringify(this.search)}, this=${this}`, this);
    return html`
      <div class="column" >
        <increase-search .period_year=${this?.period_year} @search=${({ detail }) => this.search = detail}></increase-search>
  
             
				<org-list 
					class="refresh-list"
					.period_year=${this.period_year}
					.q=${SearchIncreases}
					.search=${ { ...this.search }}
					@detail=${({ detail }) => this.detail = detail}
					@status=${({ detail }) => this.status = detail}
        > </org-list>
      </div>
      
      ${this.new_dialog_open? html`
      <newincrease-dialog @saveall=${e=>this.handle_item_saved(e.detail)}
      @newincrease-error=${e=>{this.new_dialog_error=true}}
      @newincreaseclosed=${e=>{{this.new_dialog_open=false}}}
      .period_id=${this?.current_period_data?.year}
      >
      </newincrease-dialog>` : ''}
    `;
  }
  
  handle_item_saved(selection) {
     let list = this.renderRoot.querySelector('org-list');
     
     list?.promoteTargets(selection);
  }  
  /*
     <org-list .only_chips=${true} .q=${SearchAgreements} .mdues_period=${this.mdp ? this.mdp.id : null} .search=${{...this.search, promoted: false}} @detail=${({ detail }) => this.detail = detail}  @status=${({ detail }) => this.status = detail}></org-list>
        <div class="row">
          <div id="units"><org-list .search=${this.search} @detail=${({detail}) => this.detail = detail}></org-list></div>
          <div id="detail"><entity-detail .detail=${this.detail}></entity-detail></div>
        </div>
  */
  renderExtraItems() {
      //<mwc-button slot="actionItems" icon="add"  @click=${e => this.addNewIncrease()}>New Increase&nbsp&nbsp</mwc-button>
    return html`
      ${super.renderExtraItems()}`;
  }
  
  static get properties() {
    return {
      ...(super.properties),
      search: { type: Object },
      detail: { type: Object },
      status: { type: Object }
    };
  }
  
  addNewIncrease() {
      if (!this.new_dialog_open)
        this.new_dialog_open = true;
  }  

}

window.customElements.define('increases-page', IncreasesPage);
export { IncreasesPage }

const new_increase_style = css`
  mwc-dialog {
    --mdc-dialog-scrim-color: rgba(0, 0, 0, 0.6);
    --mdc-dialog-min-width: 1300px;
    --mdc-dialog-max-width: 1300px;
    --mdc-dialog-min-height: 700px;
    --mdc-dialog-max-height: 700px;
  }      
 
  kale-textfield {
     --kale-textfield-width: 500px;
  }

.divider{
    position:absolute;
    left:50%;
    top:30%;
    bottom:5%;
    border-left:1px solid gray;
}  

.flex-row{
  display: flex;
  flex-flow: row;
}

* {
  box-sizing: border-box;
}

.column {
  width: 50%;
  padding: 10px;
  height: 300px;
  overflow-y: overlay;
}

.item-chip.unit-target {
--chip-background: var(--unit-color);
--chip-color: white;
}

.empty_message {
  height: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  font-weight: 100;
  opacity: 0.75;
}
kale-chip[ismaster] {
  background-color: var(--master-color);
}

`;

class NewIncreaseDialog extends NewItemDialog {
  static styles = new_increase_style;
  
  constructor() {
    super();
    this.selection = [];
    this.search = { promoted: false, master_only: false };
  }
  /*
  static properties = { ...super.properties, 
    table_view: {type: Boolean}, 
    item: { type: Object }, 
        editing: { type: Boolean }, 
        selectable: { type: Boolean },
         expanded: { type: Boolean }, 
         search: { type: Object },
        periods: { type: Array }, 
        period_id: { type: Number },  
        selection: { type: Array }}
  */
  static mutation_class = EditAgreement;
      
  
  static get properties() {
    return {
      ...(super.properties),
      opened: {type: Boolean },
      search: { type: Object },
      selection: { type: Array }
    };
  }
  
  dataUpdate(new_data) {
    this.item = new_data;
  }
  
  finalUpdate(new_data) {
    this.dispatchEvent(new CustomEvent('item-saved', { bubbles: true, composed: true, detail: new_data }));
  }
  
  updateList() {
    this.dispatchEvent(new CustomEvent('update-list', { bubbles: true, composed: true, detail: this.item }))
  }
  
  get mutation_instance() {
    if (!this.constructor.mutation_class) 
        return null;
    
    if (!this._mutation) 
        this._mutation = new (this.constructor.mutation_class)(
            (item) => this.dataUpdate(item),
            { changeMap: null },
            (item) => this.finalUpdate(item),
            (err) => console.error(err) //FIXME: more error checking/alerts
       );
    
    return this._mutation;
  }
  
  save_impl(data) {   
      this.dispatchEvent(new CustomEvent('saveall', { bubbles: true, composed: true, detail: this.selection })); 
  }  
  
  addSelection(target) {
      this.selection = [...this.selection, target];
      this.requestUpdate();
  }
  
  removeSelection(target) {
      this.selection =  this.selection?.filter(s=>s.id!=target.id);
      this.renderRoot.querySelector('org-list')?.push?.(target);
      this.requestUpdate();
  }

  chipDesc(agreement) {
    let units = agreement?.units;
    if (units) {
      if (units.length === 1) {
        return `${agreement.name} - ${formatAffiliateWithState(units[0].unit)}${units[0]?.unit?.subunit && String(units[0]?.unit?.subunit).trim() !== '' ? ` / subunit ${units[0]?.unit?.subunit}` : ''}`
      } else {
        return `${agreement?.name}`;
        //return `${agreement?.name} (${states.join('/')})`;
      }
      
    } else {
      return agreement.name;

    }
  }
  
  render()  {
    return  html`     
      <mwc-dialog id="newIncreaseDialog" heading="Find Increase Agreements/Units" open .title="New Increase"  
      @closed=${e=>{console.log("NewIncreaseDialog === ", e, this); this.dispatchEvent(new CustomEvent('newincreaseclosed', { bubbles: true, composed: true, detail: null })) }}>
        
        <div>
          <increase-search .mdues_period=${this?.period_id} @search=${({ detail }) => this.search = detail}></increase-search>
        </div>
        
        <div class="flex-row">
            <div class="column">
                <org-list .ignore=${this.selection || []} .only_chips=${true} .q=${SearchAgreements} .mdues_period=${this?.period_id} .search=${{...this.search, promoted: false}} 
                @detail=${({detail}) => this.detail = detail}  
                @status=${({detail}) => this.status = detail}  
                @item-selection=${({detail}) => this.addSelection(detail)}>
                </org-list>
            </div>
            <div class="column">
                ${!this.selection || this.selection.length === 0 ? html`<div class="empty_message">click results at left to add units/masters</div>` : nothing }
                ${this.selection?.map(agreement => {
                  return html`
                  <kale-chip
                    style="margin:4px;"
                    class="item-chip unit-target"
                    @click=${e => this.removeSelection(agreement)} 
                    label=${this.chipDesc(agreement)}
                    ?ismaster=${agreement?.units?.length > 1}
                ></kale-chip>`
              })} 
            </div>      

        </div>
        <mwc-button
            id="primary-action-button"
            slot="primaryAction"
            @click=${e => this.save()}
            dialogAction="increasesaved"
            ?disabled=${!(this.selection && this.selection.length > 0)}
            >
            ${this.selection && this.selection.length > 0  ? `add ${this.selection.length} item${this.selection.length > 1 ? 's' : ''}` : 'add'}
        </mwc-button>
      
        <mwc-button
            slot="secondaryAction"
            dialogAction="close">
            Cancel
        </mwc-button>

      </mwc-dialog>`
      ;
  }

}
                              
window.customElements.define('newincrease-dialog', NewIncreaseDialog);
export { NewIncreaseDialog }
