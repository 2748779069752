// Date class with better ergonomics for timeline processing:

//

const has_tz = /\d+T\d+/;

export class EventDate extends Date {
  constructor(d, incr, incr_type) {
    if (d === undefined) {
      super();
      return;
    }
    if (typeof (d) === 'string' && !has_tz.test(d)) {
      super(d ? d + "T00:00:00-05:00" : d); // add a time and TZ to avoid UTC default
    } else {
      super(d);
    }
    if (incr !== undefined) {
      switch (incr_type) {
        case undefined:
        case "month":
        case "months":
          this.setMonth(this.getMonth() + incr);
          break;
        case "day":
        case "days":
        case "date":
          this.setDate(this.getDate() + incr);
          break;
        case "year":
        case "years":
          this.setFullYear(this.getFullYear() + incr);
          break;
      }
    }
  }

  add(qty, unit) {
    if (qty !== undefined) {
      switch (unit) {
        case undefined:
        case "month":
        case "months":
          this.setMonth(this.getMonth() + qty);
          break;
        case "day":
        case "days":
        case "date":
          this.setDate(this.getDate() + qty);
          break;
        case "year":
        case "years":
          this.setFullYear(this.getFullYear() + qty);
          break;
      }
    }
  }

  equals(d) {
    return Math.abs(this - d) < (12 * 60 * 60 * 1000); //within 12 hours
  }

  get year() {
    let y = this.getFullYear();
    let soy = new Date(y, 0, 0);
    let eoy = new Date(y + 1, 0, 0);
    let frac = (this - soy) / (eoy - soy);
    return y + frac;
  }

  yearsUntil(d) {
    let a = this >= d ? this.year : d.year;
    let b = this >= d ? d.year : this.year;
    return a - b;
  }

  toString() {
    return this.str;
  }
  get localeStr() {
    return this.toLocaleDateString();
  }

  get str() {
    return this.toLocaleDateString();
    //return this.toISOString().slice(0, 10);
  }

  get nb_str() {
    return this.toLocaleDateString();
    //return this.toISOString().slice(0, 10).replace(/-/g, '‑');
  }

  get dayName() {
    return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'][this.getDay()]
  }
  get isWeekDay() {
    let d = this.getDay();
    return 0 < d && d < 6;
  }

  nextWorkday() {
    let d = new EventDate(this);
    while (!d.isWeekDay) {
      d.setDate(d.getDate() + 1);
    }
    return d;
  }

  previousAnniversaryOf(date) {
    let ret = new EventDate(date);
    let diff = this.year - ret.year;
    ret.setFullYear(ret.getFullYear() + Math.floor(diff));
    return ret;
  }
  nextAnniversaryOf(date) {
    let ret = new EventDate(date);
    let diff = this.year + 0.0001 - ret.year;
    ret.setFullYear(ret.getFullYear() + Math.ceil(diff));
    return ret;
  }





}
EventDate.september_1_1995 = new EventDate("1995-09-01"); // project staff become eligible
EventDate.january_1_2001 = new EventDate("2001-01-01"); // pension formula date A
EventDate.january_1_2014 = new EventDate("2014-01-01"); // hire date cutoff for 3 year delay in contribution requirement date, pension formula date B

EventDate.project_staff_elig_change = EventDate.september_1_1995;
EventDate.mandatory_contrib_change = EventDate.january_1_2014;

EventDate.formula_period_A = EventDate.january_1_2001;
EventDate.formula_period_B = EventDate.january_1_2014;


//const month_number = d => d.getUTCFullYear()*12 + d.getUTCMonth();
//const tzify = d => d ? d + " 00:00:00 EST" : d;
//const month_ms = 30.5*24*60*60*1000;
//const year_ms = 12 * month_ms;
//
//

