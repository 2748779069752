
import { LitElement, html, css, nothing } from 'lit';
import { KalePage } from '../shared-components/page.js';
import gql from 'graphql-tag';
import { client, SearchPeriods } from '../queries/queries.js';
import '@material/mwc-icon-button';

const mdues_page_styles = css`
  :host {
    --alias-color: var(--paper-pink-700);
    --unit-color: var(--paper-teal-700);
    --local-color: var(--paper-teal-700);
    --unit-deprecated-color: var(--paper-teal-400);
    --unit-secondary-color: var(--paper-teal-100);
    --local-secondary-color: var(--paper-teal-100);
    --master-color: var(--paper-purple-700);
    --virtual-color: var(--paper-grey-700);
    --increase-color: var(--paper-cyan-900);
    --increase-secondary: var(--paper-cyan-100);
    --period-color: var(--paper-green-700);
    --reporting-color: var(--paper-blue-800);
    --reporting-secondary: var(--paper-blue-400);
  }

  div.title {
    position: relative;
    width: 200px;
  }

  mwc-select.title {
    position: fixed;
    left: 100px;
    top: 5px;
  }
  mwc-select[slot='actionItems'], mwc-select.title {
    --mdc-theme-primary: var(--top-bar-secondary, white);
    --mdc-theme-text-primary-on-background: var(--top-bar-secondary, white);
    --mdc-theme-surface: var(--top-bar-color, purple);
    --mdc-select-fill-color: var(--top-bar-color, white);
    --mdc-select-ink-color: var(--top-bar-secondary, white);
    --mdc-select-label-ink-color: var(--top-bar-secondary, white);
    --mdc-select-dropdown-icon-color: var(--top-bar-secondary, white);

    --mdc-select-idle-line-color: var(--top-var-color);
    --mdc-select-hover-line-color: var(--top-bar-secondary);
    --mdc-select-outlined-idle-border-color: var(--top-bar-color);

    --mdc-select-outlined-hover-border-color: white;

    /* inherits the styles of mwc-menu internally */
    --mdc-menu-item-height: 30px;

    /* inherits the styles of mwc-list internally */
    --mdc-list-vertical-padding: 0px;
    --mdc-list-side-padding: 30px;
    background-color: red;
  }

  .flex-column {
    background-color: white;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column; 
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    padding: 0px;
  }

  .flex-row {
    flex: 1 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
    
  mwc-fab {
    position: fixed;
    right: 48px;
    bottom: 48px;
  }

  .column {
    background-color: white;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column; 
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    padding: 0px;
  }
  .column > * {
  }
  
  .row {
    flex: 1 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }


  mwc-select {
    --mdc-notched-outline-trailing-border-radius: 0 28px 28px 0;
  }

  mwc-icon-button[toggled] {
    background-color: var(--paper-grey-100);
    border-radius: 100%;
    color: var(--top-bar-color);
  }

  mwc-icon-button {
    margin-right: 20px;
    transform: scale(1) rotate(0);
  }

  div.pending_control {
    position: relative;
    overflow: visible;
  }
  div.pending_control > mwc-icon-button {
    --mdc-theme-text-disabled-on-light: var(--paper-grey-400);

  }
  span.pending_badge {
    font-size: 60%;
    color: white;
    position: absolute;
    left: 28px;
    top: 6px;
    background-color: var(--paper-green-800);
    border-radius: 3px;
    padding: 2px 4px;
    color: white;
    font-weight: 700;
    visibility: hidden;
  }
  span.pending_badge[pending] {
    background-color: var(--paper-red-800);
    visibility: visible;
  }

  @keyframes pulse {
    from { transform: scale(1.2) }
    80% { transform: scale(0.95)}
    to { transform: scale(1.2)  }
  }

  @keyframes spin {
    from {transform: rotate(0deg)}
    to {transform: rotate(360deg)}
  }

  mwc-icon-button[working] {
    animation: pulse 1s infinite;
  }
  mwc-icon-button.pending_icon {
    transform: scale(1) rotate(0deg);
  }
        .header_info {
          display: inline-block;
          border-radius: 12px;
          background-color: var(--increase-secondary);
          color: var(--increase-color);
          padding: 8px 12px;
          font-size: 20px;
          margin-right: 24px;

          position: relative; 
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center; 
          overflow: hidden;
          height: 50%;
        }

        .header_info > .progress_bar {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          background-color: var(--increase-color);
          opacity: 0.2;

        }
        .info_detail {
          margin-left: 6px;
          font-size: 12px;
          opacity: 0.7;
        }
`

function abbreviateNumber(value) {
  var newValue = value;
  if (value >= 1000) {
      var suffixes = ["", "k", "m", "b","t"];
      var suffixNum = Math.floor( (""+value).length/3 );
      var shortValue = '';
      for (var precision = 2; precision >= 1; precision--) {
          shortValue = parseFloat( (suffixNum != 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
          var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
          if (dotLessShortValue.length <= 2) { break; }
      }
      if (shortValue % 1 != 0)  shortValue = shortValue.toFixed(1);
      newValue = shortValue+suffixes[suffixNum];
  }
  return newValue;
}

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export class MDuesPage extends KalePage {
  static styles = [super.styles, mdues_page_styles]
  static get properties() {
    return {
      ...(super.properties),
      search: { type: Object },
      periods: { type: Array },
			period_year: { type: Number },
      table_view: { type: Boolean},
      update_in_progress: { type: Boolean},
    };
  }
  get pending_updates() { return this._pending_updates }
  set pending_updates(p) { 
    if (this._pending_updates != p) {
      this._pending_updates = p;
      this.refreshResults?.();
      this.requestUpdate('pending_updates');
    }

  }

  refreshResults() {
    this.renderRoot?.querySelectorAll?.('.refresh-list')?.forEach(r => {
      r?.refresh?.()
    });
  }
  // async send_new_current_period_data(p) {
		// console.warn("MDUES_PAGE: DO NOTIFY CURRENT PERIOD", p);
  //   this.ignore_mdp_change = true;
  //   set_current_period_data(p, this);
  //   this.period_changed(p);
  //   this.requestUpdate('current_period_data');
  //   await this.updateComplete;
  //   this.ignore_mdp_change = false;
  // }
  //
  // set current_period_data(p) {
  //   if (this.ignore_mdp_change || get_current_period_data() === p) return;
		// console.log("SET CURRENT PERIOD", p);
  //   this.send_new_current_period_data(p);
  // }
  //
  // get current_period_data() { 
  //   return get_current_period_data();
  // }
  //
  // set mdues_period(p) {
  //   set_current_period_data(p, this);
  //   this.requestUpdate('mdues_period');
  // }
  // get mdues_period() { return get_current_period_data() }

  constructor() {
    super();
   // this.getPeriods();
  }

  async activateRoute() {
    //console.warn("PAGE ACTIVATED", this.title, this.current_period_data?.id, '=>', get_current_period_data()?.id);
  //   this.current_period_data = get_current_period_data();
		// console.log("ACTIVATE ROUTE", this.current_period_data);
  //   this.requestUpdate("current_period_data");
  //   //this.getPeriods();
  }

  get period_dependent() { return true; }
  renderPreTitle() {
    const { periods, period_year, period_dependent } = this;
		//console.log("RENDER PRE TITLE", periods, period_year, period_dependent)
    return period_dependent ? html`
    <div class="title">
      <mwc-select class="title" style="width: 130px;">
        ${periods ? periods.map(p => html`
          <mwc-list-item value=${p.year} 
          ?selected=${this.period_year === p.year} 
          @request-selected=${async e => await this.selectPeriod(p, e)}> 
          ${p.locked ? '🔒' : html`⠀⠀`} ${p.year}</mwc-list-item>
        `) : ''}
      </mwc-select>
    </div>
    ` : nothing;
      // 🖉  🔏 🔓
  }

	async selectPeriod(p, e) {
		if (e.detail.source !== 'interaction') { return; }
		this.period_year = p.year;
		window.set_current_period(p, this);
	}

  renderExtraItems() {
    return this.renderMduesTotals();
	}
  renderMduesTotals() {
    let totals = this?.periods?.find?.(p => p.year === this.period_year)?.totals;
    if (!totals ) { return nothing; }
    const stat = totals?.total_stat ?? 0;
    const done = totals?.total_members ?? 0;
    const have_inc = totals?.total_members_not_in_negotiation ?? 0;
    const average_increase = totals?.total_average_increase ?? 0;
    return html`
      <div class="header_info progress" slot="actionItems"><div class="progress_bar" style="width: ${100.0 * done / stat}%"></div>${Math.round(100.0 * done / stat)}% <span class="info_detail">(${done}/${stat})</span></div>
      <div class="header_info avg" slot="actionItems"><span class="info_detail"><mwc-icon>${average_increase < 0 ? 'arrow_downward' : 'arrow_upward'}</mwc-icon></span>${average_increase !== null ? average_increase.toLocaleString() : 0}%</div>
    `
  }
}
